import { Fragment } from 'react'
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  useFloating,
} from '@floating-ui/react'
import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import IconArrowDropUp from '@aboutbits/react-material-icons/dist/IconArrowDropUp'
import { remToPx } from '../../shared/utils/remToPx'
import { languageDescription, supportedLanguages } from '../../config/i18n'
import { useLanguage } from '../../app/LanguageProvider'

type Props = {
  className?: string
}

export function LanguagePicker({ className }: Props) {
  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: 'top-start',
    strategy: 'absolute',
    middleware: [offset(remToPx(1)), flip({ padding: remToPx(1) })],
  })

  const { language, changeLanguage } = useLanguage()

  return (
    <Menu as="div">
      {({ open }) => (
        <>
          <Menu.Button as={Fragment} ref={refs.setReference}>
            <button
              className={classNames(
                'flex items-center focus:outline-none focus:ring',
                className
              )}
            >
              {language.toUpperCase()}
              <IconArrowDropUp
                className={classNames(
                  'h-6 w-6',
                  open && 'rotate-180 transform'
                )}
              />
            </button>
          </Menu.Button>
          {open && (
            <FloatingPortal>
              <Menu.Items
                ref={refs.setFloating}
                style={floatingStyles}
                className={'rounded-lg bg-white py-2 shadow-lg'}
              >
                {supportedLanguages.map((supportedLanguage, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          'block w-full text-left px-4 py-2 focus:outline-none focus:ring min-w-26',
                          active && 'bg-gray-500'
                        )}
                        onClick={() => {
                          changeLanguage(supportedLanguage)
                        }}
                      >
                        {languageDescription[supportedLanguage]}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </FloatingPortal>
          )}
        </>
      )}
    </Menu>
  )
}
