import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/react'
import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import {
  ComponentPropsWithRef,
  ElementType,
  FocusEventHandler,
  forwardRef,
  Fragment,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  useState,
} from 'react'

import { FormattedMessage } from 'react-intl'
import IconKeyboardArrowUp from '@aboutbits/react-material-icons/dist/IconKeyboardArrowUp'
import IconKeyboardArrowDown from '@aboutbits/react-material-icons/dist/IconKeyboardArrowDown'
import { Portal } from '../../utils/Portal'
import { remToPx } from '../../utils/remToPx'
import { IconClose } from '../../svgs'

import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../types'
import { SelectEmptyPanel } from './SelectEmptyPanel'
import { SelectOption, SelectOptionState } from './SelectOption'

export type MultiSelectWrapperProps<Option, Error = unknown> = {
  options: readonly Option[] | null | undefined
  value?: Option[]
  defaultValue?: Option[]
  by?: Option extends object ? keyof Option & string : never
  error?: Error
  placeholder: ReactNode
  renderValue?: (value: Option) => ReactNode
  renderOption?: (value: Option, state: SelectOptionState) => ReactNode
  renderSelectedOption?: (value: Option) => ReactNode
  renderNoSelectableOptions?: ReactNode
  renderEmpty?: ReactNode
  renderLoading?: ReactNode
  renderError?: (error: Error) => ReactNode
  listProps?: { className?: string }
  onChange?: (option: Option[]) => void
  disabled?: boolean
  children?: (props: {
    open: boolean
    children: ReactNode
    onKeyDown: KeyboardEventHandler<HTMLButtonElement>
    onBlur: FocusEventHandler<HTMLButtonElement>
  }) => ReactNode
}

export type SelectWrapperProps<Option, Error = unknown> = {
  className?: string
  disabled?: boolean
  options: readonly Option[] | null | undefined
  value?: Option | null
  defaultValue?: Option | null
  by?: Option extends object ? keyof Option & string : never
  error?: Error
  renderValue?: (value: Option | null) => ReactNode
  renderOption?: (value: Option, state: SelectOptionState) => ReactNode
  renderEmpty?: ReactNode
  renderLoading?: ReactNode
  renderError?: (error: Error) => ReactNode
  listProps?: { className?: string }
  onChange?: (option: Option | null) => void
  children?: (props: { open: boolean; children: ReactNode }) => ReactNode
  placeholder: ReactNode
}

/**
 * This is a wrapper around `Listbox` from `@headlessui/react`.
 */
export function SelectWrapper<Option, Error = unknown>({
  value,
  defaultValue,
  options,
  by,
  error,
  renderValue,
  renderOption,
  renderEmpty,
  renderLoading,
  renderError,
  disabled = false,
  className,
  listProps,
  onChange,
  placeholder,
  children,
}: SelectWrapperProps<Option, Error>) {
  const { refs, floatingStyles } = useFloatingSelect()

  return (
    <Listbox
      value={value}
      defaultValue={defaultValue}
      onChange={(option) => {
        if (
          option !== value &&
          !(by && option && value && option[by] === value[by])
        ) {
          onChange?.(option)
        }
      }}
      disabled={disabled}
    >
      {({ value, open }) => (
        <>
          <div className={className}>
            <div ref={refs.setPositionReference}>
              <Listbox.Button as={Fragment} ref={refs.setReference}>
                {children?.({
                  open,
                  children:
                    value !== ''
                      ? renderValue
                        ? renderValue(value)
                        : typeof value === 'string'
                        ? value
                        : ''
                      : placeholder,
                })}
              </Listbox.Button>
            </div>
          </div>
          <SelectList
            {...listProps}
            as={Listbox.Options}
            style={floatingStyles}
            ref={refs.setFloating}
          >
            {error
              ? renderError?.(error)
              : options === undefined
              ? renderLoading
              : options?.length === 0
              ? renderEmpty
              : options?.map((option, index) => {
                  return (
                    <Listbox.Option
                      key={extractKeyFromOption(option, by) ?? index}
                      value={option}
                      className="group/option"
                    >
                      {(state) => (
                        <SelectOption state={state}>
                          {renderOption ? (
                            renderOption(option, state)
                          ) : renderValue ? (
                            renderValue(option)
                          ) : (
                            <>{option}</>
                          )}
                        </SelectOption>
                      )}
                    </Listbox.Option>
                  )
                })}
          </SelectList>
        </>
      )}
    </Listbox>
  )
}

/**
 * This is a wrapper around `Listbox` from `@headlessui/react`.
 */
export function MultiSelectWrapper<Option, Error = unknown>({
  value,
  defaultValue,
  options,
  by,
  error,
  placeholder,
  renderValue,
  renderOption,
  renderSelectedOption,
  renderNoSelectableOptions,
  renderEmpty,
  renderLoading,
  renderError,
  disabled = false,
  listProps,
  onChange,
  children,
}: MultiSelectWrapperProps<Option, Error>) {
  const { refs, floatingStyles } = useFloatingSelect()

  const [internalValue, setInternalValue] = useState<Option[]>(
    defaultValue ?? []
  )

  const actualValue = value ?? internalValue

  function handleChange(newValue: Option[]) {
    if (!value) {
      setInternalValue(newValue)
    }
    onChange?.(newValue)
    resetFocusOption()
  }

  function removeOption(option: Option) {
    if (!value) {
      setInternalValue((prev) => {
        const newValue = prev.filter((o) => o !== option)
        onChange?.(newValue)
        return newValue
      })
    } else {
      onChange?.(value.filter((v) => v !== option))
    }
    resetFocusOption()
  }

  const { focusedOptionIndex, resetFocusOption, onKeyDown, onBlur } =
    useFocusedOption({
      value: actualValue,
      onRemove: removeOption,
    })

  const selectableOptions = options?.filter(
    (option) =>
      !actualValue.some(
        (valueOption) =>
          extractKeyFromOption(option, by) ===
          (extractKeyFromOption(valueOption, by) ?? valueOption)
      )
  )

  return (
    <Listbox
      multiple
      value={actualValue}
      defaultValue={defaultValue}
      by={by}
      disabled={disabled}
      onChange={handleChange}
    >
      {({ value, open }) => (
        <>
          <Listbox.Button as={Fragment} ref={refs.setReference}>
            {children?.({
              open,
              onKeyDown,
              onBlur,
              children:
                value.length > 0 ? (
                  <div className="flex flex-wrap gap-x-1 gap-y-1.5 pb-1">
                    {value.map((option, index) => (
                      <SelectChip
                        key={extractKeyFromOption(option, by) ?? index}
                        option={option}
                        renderFunction={renderSelectedOption ?? renderValue}
                        focused={focusedOptionIndex === index}
                        disabled={disabled}
                        onClick={(e) => {
                          e.stopPropagation()
                          removeOption(option)
                        }}
                      />
                    ))}
                  </div>
                ) : (
                  <>{placeholder}</>
                ),
            })}
          </Listbox.Button>
          <SelectList
            {...listProps}
            as={Listbox.Options}
            style={floatingStyles}
            ref={refs.setFloating}
          >
            {error ? (
              renderError?.(error)
            ) : options === undefined ? (
              renderLoading
            ) : options?.length === 0 ? (
              renderEmpty
            ) : selectableOptions?.length === 0 ? (
              renderNoSelectableOptions ? (
                renderNoSelectableOptions
              ) : (
                <SelectEmptyPanel>
                  <FormattedMessage
                    id="shared.form.select.noOptions"
                    defaultMessage="No options available"
                  />
                </SelectEmptyPanel>
              )
            ) : (
              selectableOptions?.map((option, index) => {
                return (
                  <Listbox.Option
                    key={extractKeyFromOption(option, by) ?? index}
                    value={option}
                    className="group/option"
                  >
                    {(state) => (
                      <SelectOption state={state}>
                        {renderOption ? (
                          renderOption(option, state)
                        ) : renderValue ? (
                          renderValue(option)
                        ) : (
                          <>{option}</>
                        )}
                      </SelectOption>
                    )}
                  </Listbox.Option>
                )
              })
            )}
          </SelectList>
        </>
      )}
    </Listbox>
  )
}

function SelectChip<Option>({
  option,
  focused,
  disabled,
  renderFunction,
  onClick,
}: {
  option: Option
  focused: boolean
  disabled: boolean
  renderFunction?: (option: Option) => ReactNode
  onClick: MouseEventHandler<HTMLDivElement>
}) {
  return (
    // The focus is handled by the parent
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
      className={classNames(
        'inline-flex min-h-6 items-center gap-x-1 rounded-full self-center      bg-white    text-sm  outline-2 -outline-offset-2 outline-primary-500  border px-1 ',
        focused && 'outline',
        disabled && 'bg-secondary-500/10 text-secondary-200',
        !disabled && 'cursor-pointer  hover:bg-gray-500 active:bg-secondary-200'
      )}
    >
      {renderFunction ? renderFunction(option) : <>{option}</>}
      <IconClose className={'size-4'} />
    </div>
  )
}

function useFocusedOption<Option>({
  value,
  onRemove,
}: {
  value: Option[]
  onRemove: (option: Option) => void
}) {
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number | null>(
    null
  )

  function onKeyDown(event: KeyboardEvent<HTMLButtonElement>) {
    if (event.key === 'Backspace') {
      if (focusedOptionIndex !== null) {
        const option = value[focusedOptionIndex]
        if (option) {
          onRemove(option)
        }
      } else {
        const option = value[value.length - 1]
        if (option) {
          onRemove(option)
        }
      }
    }

    if (event.key === 'Delete' && focusedOptionIndex !== null) {
      const option = value[focusedOptionIndex]
      if (option) {
        onRemove(option)
      }
    }

    if (event.key === 'ArrowLeft') {
      setFocusedOptionIndex((prev) =>
        prev === null ? value.length - 1 : Math.max(0, prev - 1)
      )
    }

    if (event.key === 'ArrowRight') {
      setFocusedOptionIndex((prev) =>
        prev === null ? null : prev + 1 >= value.length ? null : prev + 1
      )
    }
  }

  function onBlur() {
    setFocusedOptionIndex(null)
  }

  function resetFocusOption() {
    setFocusedOptionIndex(null)
  }

  return { focusedOptionIndex, resetFocusOption, onKeyDown, onBlur }
}

function extractKeyFromOption<Option>(
  option: Option,
  by?: Option extends object ? keyof Option & string : never
): string | number | undefined {
  const keyFromOption =
    option && typeof option === 'object' && by ? option[by] : option

  return keyFromOption &&
    (typeof keyFromOption === 'string' || typeof keyFromOption === 'number')
    ? keyFromOption
    : undefined
}

export type SelectInputProps = ComponentPropsWithRef<'button'> & {
  open?: boolean
  disabled?: boolean
}

export const SelectInput = forwardRef<HTMLButtonElement, SelectInputProps>(
  function SelectInput({ disabled, open, className, children, ...props }, ref) {
    return (
      <div
        className={classNames(
          'w-full ',
          disabled
            ? 'border-b border-gray-500 pb-px'
            : [
                open
                  ? 'border-b-2 pb-0 border-primary-500'
                  : 'border-b pb-px [&:focus-within]:border-b-2 [&:focus-within]:pb-0 border-gray-500 [&:focus-within]:border-primary-500 ',
              ]
        )}
      >
        <button
          type="button"
          {...props}
          ref={ref}
          disabled={disabled}
          className={classNames(
            'flex w-full flex-nowrap gap-x-2 text-left outline-none focus:ring-0 items-center',
            disabled && 'text-neutral-500'
          )}
        >
          <div className="flex-1  break-words">{children}</div>
          <SelectChevron open={open} disabled={disabled} />
        </button>
      </div>
    )
  }
)

export type SelectListProps<E extends ElementType = 'div'> =
  PolymorphicComponentPropWithRef<E, { className?: string }>

export const SelectList = forwardRef(function DropdownList<
  E extends ElementType = 'div'
>(
  { as, className, children, ...props }: SelectListProps<E>,
  ref: PolymorphicRef<E>
) {
  const Element = as ?? 'div'

  return (
    <Portal>
      <Element
        {...props}
        ref={ref}
        className={classNames(
          'flex overflow-hidden rounded-lg bg-gray-300 border  border-gray-500  shadow-sm focus:outline-none',
          className
        )}
        onClick={(event) => {
          event.stopPropagation()
          props.onClick?.(event) // eslint-disable-line @typescript-eslint/no-unsafe-call
        }}
      >
        <div className="flex-1 overflow-auto py-2">{children}</div>
      </Element>
    </Portal>
  )
})

export interface SelectChevronProps {
  open?: boolean
  disabled?: boolean
}

export function SelectChevron({ open, disabled }: SelectChevronProps) {
  return (
    <div
      className={classNames('self-center', disabled && 'text-secondary-100')}
    >
      {open ? (
        <IconKeyboardArrowUp className={'size-6'} />
      ) : (
        <IconKeyboardArrowDown className={'size-6'} />
      )}
    </div>
  )
}

export function useFloatingSelect() {
  const padding = remToPx(1)

  return useFloating({
    whileElementsMounted: autoUpdate,
    placement: 'bottom',
    strategy: 'absolute',
    middleware: [
      offset(remToPx(0.5)),
      shift({ padding }),
      flip({ padding }),
      size({
        apply({ availableHeight, availableWidth, elements, rects }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            maxWidth: `${availableWidth}px`,
            minWidth: `${rects.reference.width}px`,
          })
        },
        padding,
      }),
    ],
  })
}
