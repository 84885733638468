import React from 'react'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import { SelectionInput } from '../../../shared/form/SelectionInput'
import {
  ActivityFieldState,
  ActivityPriority,
  Category,
  Group,
  GroupFieldConfig,
} from '../types'
import { translationsActivity } from '../translations/translationsActivity'
import { PriorityIconWithDescription } from '../priority/util'
import { ProgressWithIcon } from '../progress/ProgressWithIcon'
import { ActivityFormFields } from './types'

const ConfigSelect: React.FC<{
  activityGroup: Group
  categories: Array<Category> | null
  formik: FormikProps<ActivityFormFields>
  fieldsConfig: GroupFieldConfig | undefined
}> = ({ activityGroup, categories, formik, fieldsConfig }) => {
  const intl = useIntl()
  const { values, setFieldValue } = formik
  const category = categories?.find(
    (category) => category.id === values.activityCategoryId
  )

  const updateDefaultValues = (categoryId: string): void => {
    setFieldValue('activityCategoryId', categoryId)

    const category = categories?.find((category) => category.id === categoryId)

    if (category?.defaultTypeId) {
      setFieldValue('activityTypeId', category?.defaultTypeId)
    } else {
      setFieldValue('activityTypeId', '')
    }

    if (category?.defaultProgressId) {
      let progress = category?.progresses.find((progress) => {
        return progress.id === category.defaultProgressId
      })

      if (progress && progress.activityGroupIds.includes(activityGroup.id)) {
        setFieldValue('activityProgressId', progress.id)
      } else {
        setFieldValue('activityProgressId', '')
      }
    } else {
      setFieldValue('activityProgressId', '')
    }
  }

  return categories ? (
    <>
      {fieldsConfig?.activityCategoryId.state !== ActivityFieldState.Hidden && (
        <SelectionInput
          name="activityCategoryId"
          label={intl.formatMessage(translationsActivity.detailHeroCategory)}
          onChange={(value) => updateDefaultValues(value ?? '')}
          required={fieldsConfig?.activityCategoryId.required}
          disabled={
            fieldsConfig?.activityCategoryId.state ===
            ActivityFieldState.ReadOnly
          }
          placeholder={intl.formatMessage({
            id: 'activity.form.select.category',
            defaultMessage: 'Select category',
          })}
          options={categories
            .sort((a, b) => a.sort.localeCompare(b.sort))
            .map((category) => category.id)}
          renderValue={(option) =>
            categories &&
            categories.find((category) => category.id === option)?.name
          }
        />
      )}
      {fieldsConfig?.activityTypeId.state !== ActivityFieldState.Hidden && (
        <SelectionInput
          name="activityTypeId"
          label={intl.formatMessage(translationsActivity.detailHeroType)}
          required={fieldsConfig?.activityTypeId.required}
          disabled={
            fieldsConfig?.activityTypeId.state === ActivityFieldState.ReadOnly
          }
          placeholder={intl.formatMessage({
            id: 'activity.form.select.type',
            defaultMessage: 'Select type',
          })}
          options={category?.types
            .sort((a, b) => a.sort.localeCompare(b.sort))
            .map((type) => type.id)}
          renderValue={(id) =>
            id && category?.types.find((type) => type.id === id)?.name
          }
        />
      )}
      {fieldsConfig?.activityProgressId.state !== ActivityFieldState.Hidden && (
        <SelectionInput
          name="activityProgressId"
          label={intl.formatMessage(translationsActivity.detailHeroProgress)}
          required={fieldsConfig?.activityProgressId.required}
          disabled={
            fieldsConfig?.activityProgressId.state ===
            ActivityFieldState.ReadOnly
          }
          placeholder={intl.formatMessage({
            id: 'activity.form.select.progress',
            defaultMessage: 'Select progress',
          })}
          options={category?.progresses
            .filter((progress) =>
              progress.activityGroupIds.includes(activityGroup.id)
            )
            .sort((a, b) => a.sort.localeCompare(b.sort))
            .map((progress) => progress.id)}
          renderValue={(option) =>
            option && (
              <ProgressWithIcon
                progress={category?.progresses.find(
                  (progress) => progress.id === option
                )}
              />
            )
          }
        />
      )}
      {fieldsConfig?.priority.state !== ActivityFieldState.Hidden && (
        <SelectionInput
          name="priority"
          label={intl.formatMessage(translationsActivity.priority)}
          required={fieldsConfig?.priority.required}
          disabled={
            fieldsConfig?.priority.state === ActivityFieldState.ReadOnly
          }
          placeholder={intl.formatMessage({
            id: 'activity.form.select.priority',
            defaultMessage: 'Select priority',
          })}
          options={Object.values(ActivityPriority)}
          renderValue={(option) =>
            option && <PriorityIconWithDescription priority={option} />
          }
        />
      )}
    </>
  ) : null
}

export { ConfigSelect }
