import * as Sentry from '@sentry/browser'
import { Authority } from '../../auth/types'
import { checkSyncPermissions } from '../../shared/utils/syncUtil'
import { getCostCenterGroups, getCostCenters } from './api'
import {
  clearCostCenterGroups,
  clearCostCenters,
  overrideCostCenterGroups,
  overrideCostCenters,
} from './db/costCenters'

const syncCostCenter = async (authorities: Array<Authority>): Promise<void> => {
  try {
    if (!checkSyncPermissions(authorities, Authority.TIME_TRACKING_READ)) {
      return
    }

    const costCenterGroups = await getCostCenterGroups()
    await overrideCostCenterGroups(costCenterGroups)

    const costCenters = await getCostCenters()
    await overrideCostCenters(costCenters)
  } catch (error: any) {
    if (error.response.status === 401) {
      await clearCostCenterGroups()
      await clearCostCenters()
    } else {
      Sentry.captureException(error)
    }
  }
}

export { syncCostCenter }
