import { LinkProps, NavLink } from 'react-router-dom'
import React, { ComponentType } from 'react'
import { useMatchMediaQuery } from '@aboutbits/react-toolbox'
import { IconProps } from '../../shared/svgs/types'
import { MenuLocked } from './MenuLocked'
import { useMenuToggle } from './MenuContext'

export interface MenuLinkProps extends LinkProps {
  text: string
  icon: ComponentType<IconProps>
  locked: boolean
}

export const MenuLink: React.FC<MenuLinkProps> = ({
  text,
  icon: Icon,
  locked,
  className,
  ...props
}) => {
  const toggleMenu = useMenuToggle()

  const isMobile = useMatchMediaQuery('(max-width: 767px)')

  return locked ? (
    <li className={className}>
      <MenuLocked text={text} icon={Icon} />
    </li>
  ) : (
    <li className={className}>
      <NavLink
        onClick={isMobile ? toggleMenu : undefined}
        {...props}
        className={({ isActive }) =>
          'flex flex-row items-center py-1 px-4 text-sm rounded-full outline-none fill-current' +
          (isActive
            ? ' bg-primary-500 hover:bg-primary-700 focus:bg-primary-700'
            : ' bg-white hover:bg-gray-300 focus:bg-gray-300')
        }
      >
        <Icon className="mr-4 size-6 fill-current" />
        <div className="min-w-0 flex-1 break-words">{text}</div>
      </NavLink>
    </li>
  )
}
