import { Authority } from '../../auth/types'

export enum RecordType {
  WORK_START = 'work_start',
  WORK_STOP = 'work_stop',
  WORK_PAUSE = 'work_pause',
  PROJECT_COSTCENTER = 'project_costcenter',
}

export const recordTypeAuthorityRecord: Record<
  RecordType,
  {
    createAuthority: Authority
    editAuthority: Authority
    deleteAuthority: Authority
  }
> = {
  [RecordType.WORK_START]: {
    createAuthority: Authority.TIME_TRACKING_START_CREATE,
    editAuthority: Authority.TIME_TRACKING_START_EDIT,
    deleteAuthority: Authority.TIME_TRACKING_START_DELETE,
  },
  [RecordType.WORK_STOP]: {
    createAuthority: Authority.TIME_TRACKING_STOP_CREATE,
    editAuthority: Authority.TIME_TRACKING_STOP_EDIT,
    deleteAuthority: Authority.TIME_TRACKING_STOP_DELETE,
  },
  [RecordType.WORK_PAUSE]: {
    createAuthority: Authority.TIME_TRACKING_PAUSE_CREATE,
    editAuthority: Authority.TIME_TRACKING_PAUSE_EDIT,
    deleteAuthority: Authority.TIME_TRACKING_PAUSE_DELETE,
  },
  [RecordType.PROJECT_COSTCENTER]: {
    createAuthority: Authority.TIME_TRACKING_PROJECT_AND_COST_CENTER_CREATE,
    editAuthority: Authority.TIME_TRACKING_PROJECT_AND_COST_CENTER_EDIT,
    deleteAuthority: Authority.TIME_TRACKING_PROJECT_AND_COST_CENTER_DELETE,
  },
}

export type TimeTrackingRecord = {
  id: string
  time: string
  location: {
    longitude: number | null
    latitude: number | null
  }
  type: RecordType
  description: string | null
  projectId: string | null
  costCenterId: string | null
}

export enum TimeTrackingBufferAction {
  UPSERT = 'upsert',
  DELETE = 'delete',
}

export type TimeTrackingBufferEntry<T> = NewTimeTrackingBufferEntry<T> & {
  id: number
}

export type NewTimeTrackingBufferEntry<T> = {
  timeTrackingId: string
  timestamp: Date
  action: TimeTrackingBufferAction
  payload: T
}

export type TimeTrackingBufferUpsertPayload = TimeTrackingRecord
export type TimeTrackingBufferDeletePayload = null

export type TimeTrackingBufferPayloads =
  | TimeTrackingBufferUpsertPayload
  | TimeTrackingBufferDeletePayload

export function isUpsertAction(
  data: TimeTrackingBufferEntry<TimeTrackingBufferPayloads>
): data is TimeTrackingBufferEntry<TimeTrackingBufferUpsertPayload> {
  return data.action === TimeTrackingBufferAction.UPSERT
}

export function isDeleteAction(
  data: TimeTrackingBufferEntry<TimeTrackingBufferPayloads>
): data is TimeTrackingBufferEntry<TimeTrackingBufferDeletePayload> {
  return data.action === TimeTrackingBufferAction.DELETE
}
