import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { TabPanels, Tabs } from '@reach/tabs'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import {
  useGetActivityById,
  useGetActivityCategories,
  useGetActivityGroup,
} from '../db/activities'
import { ContentArea } from '../../../shared/content'
import { Error } from '../../../shared/error'
import { IconAddCircleOutline } from '../../../shared/svgs'
import { SectionActions } from '../../../shared/section/SectionActions'
import { DeleteButton } from '../../../shared/button/DeleteButton'
import { ButtonInternalLink, Variant } from '../../../shared/button/Button'
import { LazyTabPanel } from '../../../shared/tab/LazyTabPanel'
import { TabListNavigation } from '../../../shared/tab/TabListNavigation'
import { TabListElement } from '../../../shared/tab/TabListElement'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { SectionListLink } from '../../../shared/section/SectionListLink'
import { FileSection } from '../../file/detail/FileSection'
import { NotificationOverviewByResource } from '../../notification/overview/NotificationOverviewByResource'
import { useHasAuthority } from '../../../auth/hooks'
import { Authority } from '../../../auth/types'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsActivity } from '../translations/translationsActivity'
import { translationsNotification } from '../../notification/translations/translationsNotification'
import { RelatedActivitiesAddButton } from '../related/RelatedActivitiesAddButton'
import { ActivityFieldState, Group } from '../types'
import { ActivityDetailHero } from './ActivityDetailHero'
import { ActivityDescriptionSection } from './ActivityDescriptionSection'
import { ActivityProjectSection } from './ActivityProjectSection'
import { ActivityArticleSection } from './ActivityArticleSection'
import { ActivitySignaturSection } from './ActivitySignaturSection'
import { ActivityAddressSection } from './ActivityAddressSection'
import { ActivityDeleteDialog } from './ActivityDeleteDialog'
import { ActivityHierarchy } from './ActivityHierarchy'
import { ActivityDetailFloatingActionButtons } from './ActivityDetailFloatingActionButtons'

const tabs = ['details', 'notifications', 'hierarchies']

const ActivityDetail: React.FC<{
  activityId: string
  activityGroupId: string
  tab: string | null
}> = ({ activityId, activityGroupId, tab }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const { data: activityData, error: activityError } =
    useGetActivityById(activityId)

  const { data: categories } = useGetActivityCategories()
  const { data: activityGroup } = useGetActivityGroup(activityGroupId)

  const tabIndex = tab ? Math.max(tabs.indexOf(tab), 0) : 0

  const hasEditPermissions =
    useHasAuthority(Authority.ACTIVITIES_EDIT) &&
    !activityGroup?.actionsConfig.edit.hidden

  const hasDeletePermissions =
    useHasAuthority(Authority.ACTIVITIES_DELETE) &&
    !activityGroup?.actionsConfig.delete.hidden

  const filterSelectedGroups = useCallback(
    (group: Group) => {
      return (
        activityGroup?.detailsConfig.subActivityCreate.selectableActivityGroupIds.includes(
          group.id
        ) ?? false
      )
    },
    [activityGroup]
  )

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsActivity.detailBack)}
            onClick={() => navigate(-1)}
          />
        }
      >
        {activityData ? (
          <HeaderTitle>{activityData.name}</HeaderTitle>
        ) : (
          <HeaderTitle>
            <FormattedMessage {...translationsActivity.detailTitle} />
          </HeaderTitle>
        )}
      </HeaderArea>
      <Tabs
        index={tabIndex}
        onChange={(index) =>
          navigate({ search: '?tab=' + tabs[index] }, { replace: true })
        }
      >
        <TabListNavigation>
          <TabListElement index={0}>
            <FormattedMessage
              id="activity.address.detail.tab.details"
              defaultMessage="Details"
            />
          </TabListElement>
          <TabListElement index={1}>
            <FormattedMessage
              id="activity.address.detail.tab.notifications"
              defaultMessage="Notifications"
            />
          </TabListElement>
          <TabListElement index={2}>
            <FormattedMessage
              id="activity.address.detail.tab.hierarchies"
              defaultMessage="Linked activities"
            />
          </TabListElement>
        </TabListNavigation>

        <TabPanels>
          <LazyTabPanel index={0}>
            <ContentArea>
              {activityData && categories && activityGroup && (
                <AsyncView
                  data={activityData}
                  error={activityError}
                  renderSuccess={(activityData) => (
                    <ActivityDetailHero
                      activity={activityData}
                      categories={categories}
                      activityGroup={activityGroup}
                    />
                  )}
                  renderError={(activityError) => (
                    <Error text={activityError && activityError.message} />
                  )}
                />
              )}
              {activityGroup?.fields.description.state !==
                ActivityFieldState.Hidden &&
                activityData?.description && (
                  <AsyncView
                    data={activityData}
                    error={activityError}
                    renderSuccess={(activityData) => (
                      <ActivityDescriptionSection activity={activityData} />
                    )}
                    renderError={(activityError) => (
                      <Error text={activityError && activityError.message} />
                    )}
                  />
                )}
              {activityGroup?.fields.addressId.state !==
                ActivityFieldState.Hidden &&
                activityData?.addressId && (
                  <ActivityAddressSection addressId={activityData.addressId} />
                )}
              {activityGroup?.fields.projectId.state !==
                ActivityFieldState.Hidden &&
                activityData?.projectId && (
                  <ActivityProjectSection projectId={activityData.projectId} />
                )}
              {activityGroup?.fields.articles.state !==
                ActivityFieldState.Hidden &&
                activityData &&
                activityData.articles?.length > 0 && (
                  <ActivityArticleSection articleIds={activityData.articles} />
                )}
              {activityGroup?.fields.files.state !==
                ActivityFieldState.Hidden &&
                activityData &&
                activityData.files?.length > 0 && (
                  <FileSection files={activityData.files} />
                )}
              {activityGroup?.fields.signature.state !==
                ActivityFieldState.Hidden &&
                activityData && (
                  <ActivitySignaturSection
                    activityId={activityData.id}
                    activityGroupId={activityGroupId}
                    signature={activityData.signature}
                  />
                )}
              <SectionActions>
                {hasDeletePermissions && (
                  <DeleteButton onClick={() => setShowDeleteDialog(true)} />
                )}
                {hasEditPermissions && (
                  <ButtonInternalLink
                    to={`/activity-groups/${activityGroupId}/activities/${activityId}/edit`}
                    variant={Variant.primary}
                  >
                    <span className="flex h-full items-center justify-center px-6">
                      <FormattedMessage {...translationsShared.edit} />
                    </span>
                  </ButtonInternalLink>
                )}
              </SectionActions>
            </ContentArea>
          </LazyTabPanel>
          <LazyTabPanel index={1}>
            <ContentArea>
              <NotificationOverviewByResource
                resourceType={ResourceType.activity}
                resourceId={activityId}
              />
              <SectionListLink
                to={`/activity-groups/${activityGroupId}/activities/${activityId}/notifications/add`}
              >
                <IconAddCircleOutline height="24" />
                <span className="ml-1">
                  <FormattedMessage {...translationsNotification.resourceAdd} />
                </span>
              </SectionListLink>
            </ContentArea>
          </LazyTabPanel>
          <LazyTabPanel index={2}>
            <ContentArea>
              <ActivityHierarchy activityId={activityId} />
              <SectionActions>
                {activityGroup &&
                  !activityGroup.detailsConfig.subActivityCreate.hidden && (
                    <RelatedActivitiesAddButton
                      label={
                        <FormattedMessage
                          id="activity.detail.hierachies.addChild"
                          defaultMessage="Add child activity"
                        />
                      }
                      parentId={activityId}
                      projectId={activityData?.projectId}
                      addressId={activityData?.addressId}
                      filter={filterSelectedGroups}
                    />
                  )}
              </SectionActions>
            </ContentArea>
          </LazyTabPanel>
        </TabPanels>
      </Tabs>
      <ActivityDeleteDialog
        activityId={activityId}
        isOpen={showDeleteDialog}
        onDismiss={() => setShowDeleteDialog(false)}
      />
      {activityData && activityGroup && (
        <ActivityDetailFloatingActionButtons
          activityData={activityData}
          activityGroup={activityGroup}
          hasEditPermissions={hasEditPermissions}
          hasDeletePermissions={hasDeletePermissions}
          setShowDeleteDialog={setShowDeleteDialog}
        />
      )}
    </>
  )
}

export { ActivityDetail }
