import { defineMessages, MessageDescriptor } from 'react-intl'
import { ActivityPriority } from '../types'

export const translationsActivity = defineMessages({
  addressDialogListEmpty: {
    id: 'activity.address.dialog.empty',
    defaultMessage: 'No addresses available',
  },
  addressDialogSearchStart: {
    id: 'activity.address.dialog.search.start',
    defaultMessage: 'Search addresses',
  },
  addressDialogSearchResult: {
    id: 'activity.address.dialog.search.result',
    defaultMessage: 'Search result',
  },
  addressDialogSearchEmpty: {
    id: 'activity.address.dialog.search.empty',
    defaultMessage: 'No addresses could be found',
  },
  assigneeDialogListEmpty: {
    id: 'activity.assignee.dialog.empty',
    defaultMessage: 'No users available',
  },
  assigneeDialogSearchStart: {
    id: 'activity.assignees.dialog.search.start',
    defaultMessage: 'Search users',
  },
  assigneeDialogSearchResult: {
    id: 'activity.assignee.dialog.search.result',
    defaultMessage: 'Search result',
  },
  assigneeDialogSearchEmpty: {
    id: 'activity.assignee.dialog.search.empty',
    defaultMessage: 'No users could be found',
  },
  addressDetailTitle: {
    id: 'activity.address.detail.title',
    defaultMessage: 'Address Details',
  },
  overviewTitle: {
    id: 'activity.overview.title',
    defaultMessage: 'Activities',
  },
  overviewListEmpty: {
    id: 'activity.overview.list.empty',
    defaultMessage: 'No activities available',
  },
  overviewFilterAddressTitle: {
    id: 'activity.overview.filter.address.title',
    defaultMessage: 'Filter by address',
  },
  overviewFilterAddressSearch: {
    id: 'activity.overview.filter.address.search',
    defaultMessage: 'Search address',
  },
  overviewFilterAssigneeTitle: {
    id: 'activity.overview.filter.assignee.title',
    defaultMessage: 'Filter by address',
  },
  overviewFilterAssigneeSearch: {
    id: 'activity.overview.filter.assignee.search',
    defaultMessage: 'Search users',
  },
  detailTitle: {
    id: 'activity.detail.title',
    defaultMessage: 'Activity Details',
  },
  detailBack: {
    id: 'activity.detail.back',
    defaultMessage: 'Back to activities',
  },
  detailDateDuration: {
    id: 'activity.detail.date.duration',
    defaultMessage: 'Duration',
  },
  detailBillable: {
    id: 'activity.detail.billable',
    defaultMessage: 'Billable',
  },
  detailHeroType: {
    id: 'activity.detail.hero.type',
    defaultMessage: 'Type',
  },
  detailHeroCategory: {
    id: 'activity.detail.hero.category',
    defaultMessage: 'Category',
  },
  detailHeroProgress: {
    id: 'activity.detail.hero.progress',
    defaultMessage: 'Progress',
  },
  detailArticle: {
    id: 'activity.detail.article',
    defaultMessage: 'Needed article',
  },
  signTitle: {
    id: 'activity.sign.title',
    defaultMessage: 'Sign activity',
  },
  signFormName: {
    id: 'activity.sign.form.name',
    defaultMessage: 'Name',
  },
  addTitle: {
    id: 'activity.add.title',
    defaultMessage: 'Add activity',
  },
  editTitle: {
    id: 'activity.edit.title',
    defaultMessage: 'Edit activity',
  },
  formGeneralName: {
    id: 'activity.form.general.name',
    defaultMessage: 'Name',
  },
  formGeneralDescription: {
    id: 'activity.form.general.description',
    defaultMessage: 'Description',
  },
  formValidationStartDateBeforeEndDate: {
    id: 'activity.form.validation.startDateBeforeEndDate',
    defaultMessage:
      'The start date and time must be before the end date and time.',
  },
  articleAdd: {
    id: 'activity.article.add',
    defaultMessage: 'Add article',
  },
  articleSearchStart: {
    id: 'activity.article.search.start',
    defaultMessage: 'Search articles',
  },
  articleAmountTitle: {
    id: 'activity.article.amount.title',
    defaultMessage: 'Set amount',
  },
  articleQRTitle: {
    id: 'activity.article.qr.title',
    defaultMessage: 'Scan QR code',
  },
  notificationAdd: {
    id: 'activity.notification.add',
    defaultMessage: 'Add Notification',
  },
  priority: {
    id: 'activity.priority',
    defaultMessage: 'Priority',
  },
  priorityVeryLow: {
    id: 'activity.priority.veryLow',
    defaultMessage: 'Very low',
  },
  priorityLow: {
    id: 'activity.priority.low',
    defaultMessage: 'Low',
  },
  priorityNormal: {
    id: 'activity.priority.normal',
    defaultMessage: 'Normal',
  },
  priorityHigh: {
    id: 'activity.priority.high',
    defaultMessage: 'High',
  },
  priorityVeryHigh: {
    id: 'activity.priority.veryHigh',
    defaultMessage: 'Very high',
  },
})

export function translationsPriority(
  priority: ActivityPriority
): MessageDescriptor {
  switch (priority) {
    case ActivityPriority.veryLow:
      return translationsActivity.priorityVeryLow
    case ActivityPriority.low:
      return translationsActivity.priorityLow
    case ActivityPriority.normal:
      return translationsActivity.priorityNormal
    case ActivityPriority.high:
      return translationsActivity.priorityHigh
    case ActivityPriority.veryHigh:
      return translationsActivity.priorityVeryHigh
  }
}
