import React, { SVGProps } from 'react'
import classNames from 'classnames'

export default function IconPriorityLow({
  className,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-[#1BA1E2]', className)}
      {...props}
    >
      <g id="icon">
        <circle
          cx="10.5"
          cy="10.5"
          r="9"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeMiterlimit="10"
        />
        <line
          x1="14.2"
          y1="14.2"
          x2="6.5"
          y2="6.5"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
        <line
          x1="14.5"
          y1="14.5"
          x2="14.5"
          y2="7.5"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
        <line
          x1="14.5"
          y1="14.5"
          x2="7.5"
          y2="14.5"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  )
}
