import * as Sentry from '@sentry/browser'
import { Authority } from '../../auth/types'
import { checkSyncPermissions } from '../../shared/utils/syncUtil'
import { getAddressConfig, getAddresses } from './api'
import { clearAddresses, overrideAddresses } from './db/addresses'
import { overrideAddressGroups } from './db/addressGroups'
import { overrideAddressTypes } from './db/addressTypes'

const syncAddresses = async (authorities: Array<Authority>): Promise<void> => {
  try {
    if (!checkSyncPermissions(authorities, Authority.ADDRESSES_READ)) {
      return
    }

    const addresses = await getAddresses()
    await overrideAddresses(addresses)
  } catch (error: any) {
    if (error.response.status === 401) {
      await clearAddresses()
    } else {
      Sentry.captureException(error)
    }
  }
}

const syncAddressConfigs = async (
  permissions: Array<Authority>
): Promise<void> => {
  try {
    if (!checkSyncPermissions(permissions, Authority.ADDRESSES_READ)) {
      return
    }

    const addressConfigs = await getAddressConfig()
    await overrideAddressGroups(addressConfigs.addressGroups)
    await overrideAddressTypes(addressConfigs.addressTypes)
  } catch (error: any) {
    if (error.response.status === 401) {
    }
  }
}

export { syncAddresses, syncAddressConfigs }
