import {
  SelectInput,
  SelectWrapper,
  SelectWrapperProps,
} from './multiSelect/Select'

export type SelectFieldProps<Option, Error = unknown> = Pick<
  SelectWrapperProps<Option, Error>,
  | 'options'
  | 'value'
  | 'defaultValue'
  | 'placeholder'
  | 'by'
  | 'error'
  | 'renderValue'
  | 'renderOption'
  | 'renderEmpty'
  | 'renderLoading'
  | 'renderError'
  | 'className'
  | 'listProps'
  | 'onChange'
> & { disabled?: boolean }

export function SelectField<Option, Error = unknown>({
  options,
  value,
  defaultValue,
  by,
  error,
  renderValue,
  renderOption,
  renderEmpty,
  renderLoading,
  renderError,
  className,
  listProps,
  onChange,
  placeholder,
  ...inputProps
}: SelectFieldProps<Option, Error>) {
  return (
    <SelectWrapper
      options={options}
      value={value}
      defaultValue={defaultValue}
      by={by}
      error={error}
      renderValue={renderValue}
      renderOption={renderOption}
      renderEmpty={renderEmpty}
      renderLoading={renderLoading}
      renderError={renderError}
      disabled={inputProps.disabled}
      className={className}
      listProps={listProps}
      onChange={onChange}
      placeholder={placeholder}
    >
      {({ open, children }) => (
        <SelectInput {...inputProps} open={open}>
          {children}
        </SelectInput>
      )}
    </SelectWrapper>
  )
}
