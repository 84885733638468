import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import IconLocationOn from '@aboutbits/react-material-icons/dist/IconLocationOn'
import IconNextPlan from '@aboutbits/react-material-icons/dist/IconNextPlan'
import classnames from 'classnames'
import IconReadMore from '@aboutbits/react-material-icons/dist/IconReadMore'
import IconMyLocation from '@aboutbits/react-material-icons/dist/IconMyLocation'
import { SectionItem } from '../../../shared/section'
import { Size } from '../../../shared/section/SectionItem'
import {
  IconControlPointDuplicate,
  IconDelete,
  IconKeyboardArrowRight,
  IconPerson,
} from '../../../shared/svgs'
import { getDurationAsString } from '../../../shared/utils/dateUtils'
import { SectionItemActionContainer } from '../../../shared/section/SectionItemActionContainer'
import {
  SectionItemActionButton,
  SectionItemActionLink,
} from '../../../shared/section/SectionItemActionButton'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { Group, GroupListView } from '../types'
import { PriorityIcons } from '../priority/util'
import { translationsPriority } from '../translations/translationsActivity'
import { ProgressIcon } from '../progress/ProgressIcon'
import { PopulatedActivity } from './populateActivityData'
import { ActivityOverviewListItemChips } from './chip/ActivityOverviewListItemChips'

type Props = {
  activity: PopulatedActivity
  size?: Size
  chipsHidden?: boolean
}

const ActivityOverviewItemLink: React.FC<
  Props & {
    activityGroup: Group
  }
> = ({ activity, activityGroup, size = Size.xs, chipsHidden = false }) => {
  const progressHidden =
    activityGroup?.listView === GroupListView.classic &&
    activityGroup.listConfig.item.progress.hidden
  const dueDateHidden =
    activityGroup?.listView === GroupListView.classic &&
    activityGroup.listConfig.item.dueDate.hidden

  return (
    <Link
      to={`/activity-groups/${activityGroup.id}/activities/${activity.id}`}
      className="block"
    >
      <SectionItem size={size}>
        <div className="relative flex w-full flex-col">
          <div className="flex flex-1 items-center gap-x-2 md:gap-x-4">
            <div className="flex flex-1 flex-col space-y-1">
              <h2>{activity.name}</h2>
              {!activityGroup.listConfig.item.address.hidden && (
                <AddressInfo activity={activity} />
              )}
              {!activityGroup.listConfig.item.project.hidden && (
                <ProjectInfo activity={activity} />
              )}
              {!progressHidden && <ProgressInfo activity={activity} />}
              {!activityGroup.listConfig.item.priority.hidden && (
                <PriorityInfo activity={activity} />
              )}
            </div>
            <div className="mr-4 inline-flex items-center">
              {!dueDateHidden && <DueDateInfo activity={activity} />}
            </div>
          </div>
          <div className="absolute right-0 top-1/2 -translate-y-1/2">
            <IconKeyboardArrowRight className="size-6" />
          </div>
          {!chipsHidden && (
            <ActivityOverviewListItemChips
              activity={activity}
              activityGroup={activityGroup}
              className={'mr-4 mt-3'}
            />
          )}
        </div>
      </SectionItem>
    </Link>
  )
}

function ActivityOverviewItemButtonForClusterView({
  activity,
  activityGroup,
  showActions,
  onSelect,
  onDelete,
  onDuplicate,
}: {
  activity: PopulatedActivity
  activityGroup: Group & { listView: GroupListView.clusterByStartDate }
  showActions: boolean
  onSelect: () => void
  onDelete: () => void
  onDuplicate: () => void
}): ReactElement {
  const intl = useIntl()

  return (
    <div
      className={classnames(
        showActions ? ' shadow-lg' : ' ',
        'divide-y divide-gray-500'
      )}
    >
      <>
        <button
          className="block w-full text-left focus:outline-none"
          type="button"
          onClick={onSelect}
        >
          <SectionItem size={Size.xs}>
            <div className="flex-1">
              <h2>{activity.name}</h2>
              <div className="flex flex-col space-y-1 pt-1">
                {!activityGroup.listConfig.item.address.hidden && (
                  <AddressInfo activity={activity} />
                )}
                {!activityGroup.listConfig.item.project.hidden && (
                  <ProjectInfo activity={activity} />
                )}
                {activity.assignees &&
                  !activityGroup.listConfig.item.assignees.hidden && (
                    <AssigneesInfo activity={activity} />
                  )}
                {!activityGroup.listConfig.item.priority.hidden && (
                  <PriorityInfo activity={activity} />
                )}
              </div>
              <ActivityOverviewListItemChips
                activity={activity}
                activityGroup={activityGroup}
                className={'mr-4 mt-3'}
              />
            </div>
            {!activityGroup.listConfig.item.itemDuration.hidden && (
              <div className="inline-flex items-center">
                <DurationInfo activity={activity} />
              </div>
            )}
          </SectionItem>
        </button>
        {showActions && (
          <SectionItemActionContainer>
            <SectionItemActionLink
              link={`/activity-groups/${activityGroup.id}/activities/${activity.id}`}
              icon={IconReadMore}
              label={intl.formatMessage(translationsShared.details)}
            />
            {!activityGroup.actionsConfig.duplicate.hidden && (
              <SectionItemActionButton
                onClick={onDuplicate}
                icon={IconControlPointDuplicate}
                label={intl.formatMessage({
                  id: 'activity.overview.action.duplicate',
                  defaultMessage: 'Duplicate',
                })}
              />
            )}
            {!activityGroup.actionsConfig.edit.hidden && (
              <SectionItemActionLink
                link={`/activity-groups/${activityGroup.id}/activities/${activity.id}/edit`}
                label={intl.formatMessage(translationsShared.edit)}
              />
            )}
            {!activityGroup.actionsConfig.delete.hidden && (
              <SectionItemActionButton
                onClick={onDelete}
                icon={IconDelete}
                label={intl.formatMessage(translationsShared.delete)}
              />
            )}
          </SectionItemActionContainer>
        )}
      </>
    </div>
  )
}

const ProjectInfo: React.FC<{ activity: PopulatedActivity }> = ({
  activity,
}) => {
  return (
    <>
      {activity.projectName && (
        <div className="flex items-center space-x-1">
          <IconLocationOn height={14} width={14} />
          <h3 className="text-sm">{activity.projectName}</h3>
        </div>
      )}
    </>
  )
}

function AddressInfo({ activity }: { activity: PopulatedActivity }) {
  return (
    <>
      {activity.addressName && (
        <div className="flex items-center space-x-1">
          <IconMyLocation height={14} width={14} />
          <h3 className="text-sm">{activity.addressName}</h3>
        </div>
      )}
    </>
  )
}

const ProgressInfo: React.FC<{ activity: PopulatedActivity }> = ({
  activity,
}) => {
  return (
    <>
      {activity.progress && activity.progress.name && (
        <div className="flex items-center space-x-1">
          {activity.progress.icon ? (
            <>
              <ProgressIcon
                progress={activity.progress}
                className={'size-[14px]'}
              />{' '}
            </>
          ) : (
            <>
              <IconNextPlan height={14} width={14} />{' '}
            </>
          )}
          <h3 className="text-sm">{activity.progress.name}</h3>
        </div>
      )}
    </>
  )
}

const AssigneesInfo: React.FC<{ activity: PopulatedActivity }> = ({
  activity,
}) => {
  if (!activity.assignees || activity.assignees.length === 0) {
    return null
  }

  return (
    <div className="inline-flex items-center space-x-1">
      <IconPerson height={14} width={14} />
      <h3 className="text-sm">{activity.assignees.join(', ')}</h3>
    </div>
  )
}

const DurationInfo: React.FC<{ activity: PopulatedActivity }> = ({
  activity,
}) => {
  const duration = getDurationAsString(activity.startDate, activity.endDate)
  return (
    <>
      {activity.startDate && activity.endDate && (
        <span className="px-3">{duration}</span>
      )}
    </>
  )
}

const DueDateInfo: React.FC<{ activity: PopulatedActivity }> = ({
  activity,
}) => {
  return (
    <>
      {activity.dueDate && (
        <span className="px-3">
          <FormattedDate value={activity.dueDate} />
        </span>
      )}
    </>
  )
}

function PriorityInfo({ activity }: { activity: PopulatedActivity }) {
  const Icon = PriorityIcons[activity.priority]
  return (
    <div className="flex items-center space-x-1">
      <Icon height={14} width={14} className={'text-black'} />
      <h3 className="text-sm">
        <FormattedMessage {...translationsPriority(activity.priority)} />
      </h3>
    </div>
  )
}

export { ActivityOverviewItemLink, ActivityOverviewItemButtonForClusterView }
