import React from 'react'
import { IntlProvider } from 'react-intl'
import { registerLocale } from 'react-datepicker'
import { de } from 'date-fns/locale'
import translations from '../translations'
import { useLanguage } from './LanguageProvider'

registerLocale('de', de)

const Internationalization: React.FC = ({ children }) => {
  const { language } = useLanguage()

  const messages = Object.assign({}, translations[language])

  return (
    <IntlProvider key={language} locale={language} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { Internationalization }
