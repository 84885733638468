import React, { ReactNode } from 'react'
import { ErrorMessage, useField } from 'formik'
import { FieldErrorMessage } from './FieldErrorMessage'
import { InputLabel } from './InputLabel'
import { SelectField, SelectFieldProps } from './SelectField'

type Props<Option, Error = unknown> = SelectFieldProps<Option, Error> & {
  label: ReactNode
  name: string
  required?: boolean
}

export function SelectionInput<Option, Error = unknown>({
  label,
  name,
  onChange,
  ...props
}: Props<Option, Error>) {
  const [field, , helpers] = useField(name)

  return (
    <div className="flex-1 flex-col items-center">
      <div className="items-center ">
        <InputLabel htmlFor={name} label={label} />
        <SelectField
          value={field.value}
          onChange={(value) => {
            void helpers.setValue(value)
            if (onChange) {
              onChange(value)
            }
          }}
          {...props}
        />
      </div>
      <ErrorMessage name={name} component={FieldErrorMessage} />
    </div>
  )
}
