import Internationalization from '@aboutbits/internationalization'
import { Language } from '../domain/user/types'

export const supportedLanguages = [Language.DE, Language.IT]
const fallbackLanguage = Language.DE

const i18n = new Internationalization<Language>(
  supportedLanguages,
  fallbackLanguage,
  'Language'
)

export const languageDescription: Record<Language, string> = {
  [Language.DE]: 'Deutsch',
  [Language.IT]: 'Italiano',
  [Language.EN]: 'English',
}

export { i18n }
