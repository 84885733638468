import React, { useContext, useState } from 'react'
import { FormattedDate } from 'react-intl'
import { v4 as uuid } from 'uuid'
import { add, format, formatISO, intervalToDuration, parseISO } from 'date-fns'
import { PaginatedData } from '../../../db/types'
import { Activity, Group, GroupListView } from '../types'
import {
  Section,
  SectionList,
  SectionPaginationRouter,
  SectionTitle,
} from '../../../shared/section'
import { IconFormatListBulleted } from '../../../shared/svgs'
import { Empty } from '../../../shared/empty'
import { ActivityDeleteDialog } from '../detail/ActivityDeleteDialog'
import {
  extractErrorMessage,
  formatDateForAPI,
} from '../../../shared/utils/apiUtils'
import { createActivity } from '../api'
import { overrideActivity } from '../db/activities'
import { AppContext } from '../../../app/AppContext'
import {
  getNotificationsByResource,
  overrideNotification,
} from '../../notification/db/notifications'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { createNotification } from '../../notification/api'
import { getDurationSumAsString } from '../../../shared/utils/dateUtils'
import { PopulatedActivity, usePopulateActivity } from './populateActivityData'
import { ActivityOverviewItemButtonForClusterView } from './ActivityOverviewItem'

type Props = {
  empty: string
  activityGroup: Group & { listView: GroupListView.clusterByStartDate }
  data: PaginatedData<Activity>
}

type ActivityTasks = {
  [startDate: string]: PopulatedActivity[]
}

const groupedActivities = (activities: PopulatedActivity[]): ActivityTasks => {
  const groupedActivity: ActivityTasks = {}

  activities.forEach((activity) => {
    const startDate = format(parseISO(activity.startDate), 'yyyy-MM-dd')

    if (groupedActivity[startDate]) {
      groupedActivity[startDate].push(activity)
    } else {
      groupedActivity[startDate] = [activity]
    }
  })

  return groupedActivity
}

const ActivityOverviewViewClusteredByStartDate: React.FC<Props> = ({
  empty,
  data,
  activityGroup,
}) => {
  const { addAlertErrorMessage } = useContext(AppContext)
  const [selectedActivity, setSelectedActivity] = useState<string | null>()
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const activities = usePopulateActivity(data, activityGroup)
  const items = groupedActivities(activities)

  const toggleSelectedRecord = (id: string) => {
    if (selectedActivity === id) {
      setSelectedActivity(null)
    } else {
      setSelectedActivity(id)
    }
  }

  const duplicateActivity = async (activity: Activity): Promise<void> => {
    const duration = intervalToDuration({
      start: parseISO(activity.startDate),
      end: parseISO(activity.endDate),
    })

    try {
      const apiActivity: Activity = {
        ...activity,
        id: uuid(),
        startDate: formatDateForAPI(formatISO(Date.now())),
        endDate: formatDateForAPI(add(Date.now(), duration)),
        signature: null,
        articles: activity.articles.map((article) => ({
          ...article,
          id: uuid(),
        })),
      }

      const createdActivity = await createActivity(apiActivity)
      await overrideActivity(createdActivity)

      const notifications = await getNotificationsByResource(
        ResourceType.activity,
        activity.id
      )

      for (const notification of notifications) {
        const result = await createNotification({
          ...notification,
          referencedResourceType: ResourceType.activity,
          referencedResourceId: createdActivity.id,
        })

        await overrideNotification(result)
      }
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    }
  }

  return (
    <>
      <div className="space-y-8">
        {Object.keys(items)
          .sort((a, b) => Date.parse(b) - Date.parse(a))
          .map((activityStartDate, index) => (
            <Section key={index}>
              <SectionTitle
                className={'flex flex-row items-center justify-between'}
              >
                <FormattedDate value={activityStartDate} />
                {!activityGroup.listConfig.item.dayDuration.hidden && (
                  <div className={'pr-3'}>
                    {getDurationSumAsString(items[activityStartDate])}
                  </div>
                )}
              </SectionTitle>
              <SectionList>
                {items[activityStartDate].map((activity) => (
                  <ActivityOverviewItemButtonForClusterView
                    activity={activity}
                    key={activity.id}
                    activityGroup={activityGroup}
                    showActions={selectedActivity === activity.id}
                    onSelect={() => toggleSelectedRecord(activity.id)}
                    onDelete={() => setShowDeleteDialog(true)}
                    onDuplicate={() => duplicateActivity(activity)}
                  />
                ))}
              </SectionList>
            </Section>
          ))}
        {Object.keys(items).length === 0 && (
          <Empty icon={IconFormatListBulleted} text={empty} />
        )}
        <SectionPaginationRouter
          page={data.page}
          size={data.size}
          total={data.total}
        />
      </div>
      <ActivityDeleteDialog
        activityGroupId={activityGroup.id}
        activityId={selectedActivity ?? ''}
        isOpen={selectedActivity != null && showDeleteDialog}
        onDismiss={() => setShowDeleteDialog(false)}
      />
    </>
  )
}

export { ActivityOverviewViewClusteredByStartDate }
