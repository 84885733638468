import * as Sentry from '@sentry/browser'
import { getUsers } from './api'
import { overrideUsers } from './db/users'

const syncUsers = async (): Promise<void> => {
  try {
    const users = await getUsers()
    await overrideUsers(users)
  } catch (error: any) {
    if (error.response.status === 401) {
    } else {
      Sentry.captureException(error)
    }
  }
}

export { syncUsers }
