import React, { ReactNode } from 'react'

import { RecordType, TimeTrackingRecord } from '../types'
import { useCreatePermissionsTimeTracking } from '../../../auth/hooks'
import {
  TimeTrackingWidgetActionPause,
  TimeTrackingWidgetActionProjectCostCenter,
  TimeTrackingWidgetActionStart,
  TimeTrackingWidgetActionStop,
  Variant,
} from './TimeTrackingWidgetAction'

type WidgetProps = {
  lastRecordType: RecordType
}

const TimeTrackingWidget: React.FC<{
  data: TimeTrackingRecord[]
}> = ({ data }) => {
  let lastRecordType = RecordType.WORK_STOP

  if (data.length > 0) {
    const lastRecord = data.sort((a, b) => b.time.localeCompare(a.time))[0]
    lastRecordType = lastRecord.type
  }

  return (
    <div>
      <TimeTrackingWidgetDesktop lastRecordType={lastRecordType} />
      <TimeTrackingWidgetMobile lastRecordType={lastRecordType} />
    </div>
  )
}

const TimeTrackingWidgetDesktop: React.FC<WidgetProps> = ({
  lastRecordType,
}) => {
  const {
    hasCreatePermissionStart,
    hasCreatePermissionStop,
    hasCreatePermissionPause,
    hasCreatePermissionProjectCostCenter,
  } = useCreatePermissionsTimeTracking()
  return (
    <>
      {lastRecordType === RecordType.WORK_STOP ||
      lastRecordType === RecordType.WORK_PAUSE
        ? hasCreatePermissionStart && (
            <TimeTrackingWidgetDesktopContainer>
              <TimeTrackingWidgetActionStart />
            </TimeTrackingWidgetDesktopContainer>
          )
        : (hasCreatePermissionStop ||
            hasCreatePermissionPause ||
            hasCreatePermissionProjectCostCenter) && (
            <TimeTrackingWidgetDesktopContainer>
              {hasCreatePermissionStop && <TimeTrackingWidgetActionStop />}
              {hasCreatePermissionPause && <TimeTrackingWidgetActionPause />}
              {hasCreatePermissionProjectCostCenter && (
                <TimeTrackingWidgetActionProjectCostCenter />
              )}
            </TimeTrackingWidgetDesktopContainer>
          )}
    </>
  )
}

function TimeTrackingWidgetDesktopContainer({
  children,
}: {
  children: ReactNode
}) {
  return (
    <div className="hidden flex-row justify-between md:flex md:space-x-1 lg:space-x-4">
      {children}
    </div>
  )
}

const TimeTrackingWidgetMobile: React.FC<WidgetProps> = ({
  lastRecordType,
}) => {
  const {
    hasCreatePermissionStart,
    hasCreatePermissionStop,
    hasCreatePermissionPause,
    hasCreatePermissionProjectCostCenter,
  } = useCreatePermissionsTimeTracking()

  return (
    <>
      {lastRecordType === RecordType.WORK_STOP ||
      lastRecordType === RecordType.WORK_PAUSE
        ? hasCreatePermissionStart && (
            <div className="block rounded-b-lg bg-gray-700 md:hidden">
              <div className="flex flex-row justify-center py-1">
                <TimeTrackingWidgetActionStart />
              </div>
            </div>
          )
        : (hasCreatePermissionProjectCostCenter ||
            hasCreatePermissionStop ||
            hasCreatePermissionPause) && (
            <div className="block rounded-b-lg bg-gray-700 md:hidden">
              <div className="flex flex-row justify-center py-1">
                {hasCreatePermissionProjectCostCenter && (
                  <TimeTrackingWidgetActionProjectCostCenter />
                )}
              </div>
              <div className="flex flex-row divide-x divide-gray-600 border-t border-gray-600">
                {hasCreatePermissionStop && (
                  <TimeTrackingWidgetActionStop variant={Variant.inline} />
                )}
                {hasCreatePermissionPause && (
                  <TimeTrackingWidgetActionPause variant={Variant.inline} />
                )}
              </div>
            </div>
          )}
    </>
  )
}

export { TimeTrackingWidget }
