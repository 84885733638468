import * as Sentry from '@sentry/browser'
import { getProjects } from './api'
import { overrideProjects } from './db/projects'

const syncProjects = async (): Promise<void> => {
  try {
    const projects = await getProjects()
    await overrideProjects(projects)
  } catch (error: any) {
    if (error.response.status === 401) {
    } else {
      Sentry.captureException(error)
    }
  }
}

export { syncProjects }
