import React, { SVGProps } from 'react'
import classNames from 'classnames'

export default function IconPriorityVeryHigh({
  className,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-[#E51400]', className)}
      {...props}
    >
      <g id="icon">
        <circle
          cx="10.5"
          cy="10.5"
          r="9"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeMiterlimit="10"
        />
        <line
          x1="10.5"
          y1="5.9"
          x2="10.5"
          y2="15.2"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
        <line
          x1="10.5"
          y1="5.7857"
          x2="5.8245"
          y2="10.5037"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
        <line
          x1="10.5"
          y1="5.7857"
          x2="15.1626"
          y2="10.5037"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  )
}
