import React, { useContext, useEffect } from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'
import { PrimitiveType } from 'intl-messageformat'

import { SkipNavContent, SkipNavLink } from '@reach/skip-nav'
import { logout } from '../../auth/logout'
import { AuthContext } from '../../auth/AuthContext'
import { Head } from '../shared/Head'
import { Footer } from '../shared/Footer'
import { HorizontalSpacer } from '../../shared/spacing'
import { DesktopMenu, MobileMenu } from './Menu'
import { MenuProvider } from './MenuContext'

type AppLayoutProps = {
  title: {
    messageDescriptor: MessageDescriptor
    values?: Record<string, PrimitiveType>
  }
}

const AppLayout: React.FC<AppLayoutProps> = ({ title, children }) => {
  const authContext = useContext(AuthContext)

  useEffect(() => {
    if (authContext.authenticated) return // do nothing if the user is logged in
    logout()
  }, [authContext.authenticated])

  return authContext.authenticated ? (
    <MenuProvider>
      <SkipNavLink>
        <FormattedMessage
          id="app.nav.accessibility.skip-to-content"
          defaultMessage='"Skip to content"'
        />
      </SkipNavLink>
      <div className="flex min-h-screen flex-row bg-white md:bg-gray-300">
        <Head title={title} />
        <DesktopMenu />
        <MobileMenu />
        <SkipNavContent className="min-w-0 flex-1 ">
          <div className="h-screen overflow-auto">
            <div className="md:mx-4 lg:mx-8">
              <main className="mx-auto my-4 min-h-screen  md:my-16 md:min-h-0 md:max-w-content">
                {children}
              </main>
            </div>
            <HorizontalSpacer className="my-4 block md:hidden">
              <Footer />
            </HorizontalSpacer>
          </div>
        </SkipNavContent>
      </div>
    </MenuProvider>
  ) : null
}

export { AppLayout }
