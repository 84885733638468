import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { LocationContext } from '@aboutbits/react-toolbox'
import { syncTimeTracking } from '../../sync'
import { ActionButton } from '../../../../shared/button/ActionButton'
import { IconControlPointDuplicate } from '../../../../shared/svgs'
import { duplicateTimeTrackingRecord } from '../../db/records'
import { addUpsertBufferEntry } from '../../db/buffer'
import { AuthContext } from '../../../../auth/AuthContext'

const TimeTrackingItemDuplicate: React.FC<{ id: string }> = ({ id }) => {
  const { data: userPermissions } = useContext(AuthContext)
  const { location } = useContext(LocationContext)

  const duplicateRecord = async () => {
    const record = await duplicateTimeTrackingRecord(id, location)
    await addUpsertBufferEntry(record)
    if (userPermissions?.authorities) {
      await syncTimeTracking(userPermissions.authorities)
    }
  }

  return (
    <>
      <ActionButton onClick={duplicateRecord}>
        <IconControlPointDuplicate className="mr-2 size-5" />
        <FormattedMessage
          id="timeTracking.item.duplicate"
          defaultMessage="Duplicate"
        />
      </ActionButton>
    </>
  )
}

export { TimeTrackingItemDuplicate }
