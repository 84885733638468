import * as Yup from 'yup'
import { isBefore } from 'date-fns'
import { useIntl } from 'react-intl'
import { convertDateAndTimeToDate } from '../../../shared/utils/dateUtils'
import { ActivityFieldState, ActivityPriority, Group } from '../types'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsActivity } from '../translations/translationsActivity'

export type activityValidationSchemaProps = {
  requiredFieldTranslation: string
  validDateFieldTranslation: string
  validTimeFieldTranslation: string
}

export const useGetActivityValidationSchema = (config: Group | null) => {
  const intl = useIntl()
  const requiredField = intl.formatMessage(
    translationsShared.validationRequired
  )
  const validDateField = intl.formatMessage(translationsShared.validationDate)
  const validTimeField = intl.formatMessage(translationsShared.validationTime)

  return Yup.object().shape({
    name: createConditionalStringValidation(
      config?.fields.name.state !== ActivityFieldState.Hidden,
      requiredField
    ),
    description: createConditionalStringValidation(
      config?.fields.description.required,
      requiredField
    ),
    activityCategoryId: Yup.string().required(requiredField),
    activityTypeId: createConditionalStringValidation(
      config?.fields.activityTypeId.required,
      requiredField
    ),
    activityProgressId: createConditionalStringValidation(
      config?.fields.activityProgressId.required,
      requiredField
    ),
    startDate: createConditionalStringValidation(
      config?.fields.startDate.required,
      requiredField
    ).matches(/^\d\d\d\d-\d\d-\d\d$/, {
      message: validDateField,
    }),
    startTime: createConditionalStringValidation(
      config?.fields.startDate.required,
      requiredField
    ).matches(/^\d\d:\d\d$/, {
      message: validTimeField,
    }),
    endDate: createConditionalStringValidation(
      config?.fields.endDate.required,
      requiredField
    )
      .matches(/^\d\d\d\d-\d\d-\d\d$/, {
        message: validDateField,
      })
      .test(
        'startDate_must_be_before_endDate',
        intl.formatMessage(
          translationsActivity.formValidationStartDateBeforeEndDate
        ),
        function (value) {
          if (value && this.parent.endTime) {
            return isBefore(
              convertDateAndTimeToDate(
                this.parent.startDate,
                this.parent.startTime
              ),
              convertDateAndTimeToDate(value, this.parent.endTime)
            )
          } else {
            return true
          }
        }
      ),
    endTime: createConditionalStringValidation(
      config?.fields.endDate.required,
      requiredField
    )
      .matches(/^\d\d:\d\d$/, {
        message: validTimeField,
      })
      .test(
        'startDate_must_be_before_endDate',
        intl.formatMessage(
          translationsActivity.formValidationStartDateBeforeEndDate
        ),
        function (value) {
          if (value && this.parent.endDate) {
            return isBefore(
              convertDateAndTimeToDate(
                this.parent.startDate,
                this.parent.startTime
              ),
              convertDateAndTimeToDate(this.parent.endDate, value)
            )
          } else {
            return true
          }
        }
      ),
    dueDate: createConditionalStringValidation(
      config?.fields.dueDate.required,
      requiredField
    ).matches(/^\d\d\d\d-\d\d-\d\d$/, {
      message: validDateField,
    }),
    dueTime: createConditionalStringValidation(
      config?.fields.dueDate.required,
      requiredField
    ).matches(/^\d\d:\d\d$/, {
      message: validTimeField,
    }),
    addressId: createConditionalStringValidation(
      config?.fields.addressId.required,
      requiredField
    ),
    projectId: createConditionalStringValidation(
      config?.fields.projectId.required,
      requiredField
    ),
    articles: createConditionalArrayValidation(
      config?.fields.articles.required,
      requiredField
    ),
    files: createConditionalArrayValidation(
      config?.fields.files.required,
      requiredField
    ),
    billable: Yup.boolean(),
    startDateChanged: Yup.boolean(),
    endDateChanged: Yup.boolean(),
    durationChanged: Yup.boolean(),
    startTimeChanged: Yup.boolean(),
    endTimeChanged: Yup.boolean(),
    priority: createConditionalEnumValidation<ActivityPriority>(
      config?.fields.priority.required,
      requiredField,
      Object.values(ActivityPriority)
    ),
  })
}

function createConditionalStringValidation(
  required: boolean | undefined,
  message: string
): Yup.StringSchema {
  if (required) {
    return Yup.string().required(message)
  }

  return Yup.string()
}

function createConditionalArrayValidation(
  required: boolean | undefined,
  message: string
) {
  if (required === undefined || required) {
    return Yup.array().min(1, message).required(message)
  }

  return Yup.array()
}

function createConditionalEnumValidation<T>(
  required: boolean | undefined,
  message: string,
  enumValues: T[]
) {
  if (required) {
    return Yup.mixed<T>().oneOf(enumValues).required(message)
  }

  return Yup.mixed<T>().oneOf(enumValues)
}
