import { useIntl } from 'react-intl'
import React, { useState } from 'react'
import IconFormatListBulleted from '@aboutbits/react-material-icons/dist/IconFormatListBulleted'
import { Activity, Group } from '../types'
import { FloatingButton } from '../../../shared/floatingArea'
import { IconDelete, IconEdit, IconNotifications } from '../../../shared/svgs'
import { translationsNotification } from '../../notification/translations/translationsNotification'
import { translationsActivity } from '../translations/translationsActivity'
import {
  FloatingMenu,
  FloatingMenuButtonLink,
} from '../../../shared/floatingMenu/FloatingMenu'
import { ActivityAddDialog } from '../add/ActivityAddDialog'

type Props = {
  activityData: Activity
  activityGroup: Group
  hasEditPermissions: boolean
  hasDeletePermissions: boolean
  setShowDeleteDialog: (show: boolean) => void
}

export function ActivityDetailFloatingActionButtons({
  activityData,
  activityGroup,
  hasEditPermissions,
  hasDeletePermissions,
  setShowDeleteDialog,
}: Props) {
  const intl = useIntl()

  const [showDialog, setShowDialog] = useState<boolean>(false)

  const activityId = activityData.id
  const activityGroupId = activityGroup.id

  return (
    <>
      <FloatingMenu>
        {activityGroup &&
          !activityGroup.detailsConfig.subActivityCreate.hidden && (
            <FloatingButton
              onClick={() => {
                setShowDialog(true)
              }}
              className={'p-3'}
            >
              <IconFormatListBulleted
                className={'size-4'}
                title={intl.formatMessage({
                  id: 'activity.detail.hierachies.floatingButton.addChild',
                  defaultMessage: 'Add child activity',
                })}
              />
            </FloatingButton>
          )}
        <FloatingMenuButtonLink
          to={`/activity-groups/${activityGroupId}/activities/${activityId}/notifications/add`}
        >
          <IconNotifications
            className="size-4 fill-current"
            title={intl.formatMessage(translationsNotification.resourceAdd)}
          />
        </FloatingMenuButtonLink>
        {hasEditPermissions && (
          <FloatingMenuButtonLink
            to={`/activity-groups/${activityGroupId}/activities/${activityId}/edit`}
          >
            <IconEdit
              className="size-4 fill-current"
              title={intl.formatMessage(translationsActivity.editTitle)}
            />
          </FloatingMenuButtonLink>
        )}
        {hasDeletePermissions && (
          <FloatingButton
            onClick={() => {
              setShowDeleteDialog(true)
            }}
            className={'p-3'}
          >
            <IconDelete className="size-4 fill-current" />
          </FloatingButton>
        )}
      </FloatingMenu>
      {activityData && (
        <ActivityAddDialog
          isOpen={showDialog}
          query={{
            project: activityData.projectId,
            address: activityData.addressId,
            parent: activityData.id,
          }}
          onDismis={() => setShowDialog(false)}
        />
      )}
    </>
  )
}
