import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { AsyncView } from '@aboutbits/react-toolbox'
import { TabPanels, Tabs } from '@reach/tabs'
import { overrideProject, useGetProjectById } from '../db/projects'
import {
  HeaderArea,
  HeaderRightArea,
  HeaderSmallAction,
  HeaderTitle,
} from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import {
  IconAddCircleOutline,
  IconFavorite,
  IconFavoriteBorder,
} from '../../../shared/svgs'
import { markAsFavorite, unmarkAsFavorite } from '../api'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { ContentArea } from '../../../shared/content'
import { useGetActivitiesForProject } from '../../activity/db/activities'
import { SectionActions } from '../../../shared/section/SectionActions'
import { ButtonInternalLink } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'
import { useHasAuthority } from '../../../auth/hooks'
import { Authority } from '../../../auth/types'
import { FileSection } from '../../file/detail/FileSection'
import { TabListElement } from '../../../shared/tab/TabListElement'
import { TabListNavigation } from '../../../shared/tab/TabListNavigation'
import { LazyTabPanel } from '../../../shared/tab/LazyTabPanel'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { SectionListLink } from '../../../shared/section/SectionListLink'
import { NotificationOverviewByResource } from '../../notification/overview/NotificationOverviewByResource'
import { AppContext } from '../../../app/AppContext'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsProject } from '../translations/translationsProject'
import { translationsNotification } from '../../notification/translations/translationsNotification'
import { RelatedActivitiesAddButton } from '../../activity/related/RelatedActivitiesAddButton'
import { RelatedActivitiesList } from '../../activity/related/RelatedActivitiesList'
import { ProjectAddress } from './ProjectAdress'
import { ProjectAdditionalAddresses } from './ProjectAdditionalAdresses'
import { ProjectDetailsFloatingMenu } from './ProjectDetailsFloatingMenu'

const tabs = ['details', 'activities', 'notifications']

const ProjectDetail: React.FC<{
  id: string
  tab: string | null
}> = ({ id, tab }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { addAlertErrorMessage } = useContext(AppContext)
  const [disabled, setDisabled] = useState<boolean>(false)

  const onBack = (): void => {
    navigate(-1)
  }

  const { data: projectData } = useGetProjectById(id)
  const { data: relatedActivitiesData } = useGetActivitiesForProject(id)

  const tabIndex = tab ? Math.max(tabs.indexOf(tab), 0) : 0

  const hasActivitiesCreatePermissions = useHasAuthority(
    Authority.ACTIVITIES_CREATE
  )

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsProject.detailBack)}
            onClick={onBack}
          />
        }
      >
        {projectData ? (
          <>
            <HeaderTitle>{projectData.name}</HeaderTitle>
            <HeaderRightArea>
              <HeaderSmallAction
                icon={projectData.favorite ? IconFavorite : IconFavoriteBorder}
                label={
                  projectData.favorite
                    ? intl.formatMessage(translationsProject.favoriteUnmark)
                    : intl.formatMessage(translationsProject.favoriteMark)
                }
                disabled={disabled}
                onClick={async () => {
                  setDisabled(true)
                  try {
                    const updatedProject = await (projectData.favorite
                      ? unmarkAsFavorite(projectData.id)
                      : markAsFavorite(projectData.id))

                    overrideProject(updatedProject).finally(() => {
                      setDisabled(false)
                    })
                  } catch (error: any) {
                    addAlertErrorMessage(extractErrorMessage(error))
                    setDisabled(false)
                  }
                }}
              />
            </HeaderRightArea>
          </>
        ) : (
          <HeaderTitle>
            {intl.formatMessage(translationsProject.detailTitle)}
          </HeaderTitle>
        )}
      </HeaderArea>
      <Tabs
        index={tabIndex}
        onChange={(index: number) =>
          navigate({ search: '?tab=' + tabs[index] }, { replace: true })
        }
      >
        <TabListNavigation>
          <TabListElement index={0}>
            <FormattedMessage
              id="project.detail.tab.details"
              defaultMessage="Details"
            />
          </TabListElement>
          <TabListElement index={1}>
            <FormattedMessage
              id="project.detail.tab.activities"
              defaultMessage="Activities"
            />
          </TabListElement>
          <TabListElement index={2}>
            <FormattedMessage
              id="project.detail.tab.notifications"
              defaultMessage="Notifications"
            />
          </TabListElement>
        </TabListNavigation>

        <TabPanels>
          <LazyTabPanel index={0}>
            <ContentArea>
              {projectData?.addressId && (
                <ProjectAddress
                  addressId={projectData.addressId}
                  projectType={projectData.type}
                />
              )}
              {projectData?.additionalAddresses && (
                <ProjectAdditionalAddresses
                  additionalAddresses={projectData.additionalAddresses}
                />
              )}
              {projectData?.files && projectData?.files.length > 0 && (
                <FileSection files={projectData.files} />
              )}
              <SectionActions>
                <ButtonInternalLink
                  to={`/projects/${id}/edit`}
                  variant={Variant.primary}
                >
                  <FormattedMessage {...translationsShared.edit} />
                </ButtonInternalLink>
              </SectionActions>
            </ContentArea>
          </LazyTabPanel>
          <LazyTabPanel index={1}>
            <ContentArea>
              {relatedActivitiesData && relatedActivitiesData.length > 0 && (
                <AsyncView
                  data={relatedActivitiesData}
                  renderSuccess={(relatedActivitiesData) => (
                    <RelatedActivitiesList activities={relatedActivitiesData} />
                  )}
                />
              )}
              {hasActivitiesCreatePermissions && (
                <SectionActions>
                  <RelatedActivitiesAddButton
                    projectId={id}
                    addressId={projectData?.addressId}
                  />
                </SectionActions>
              )}
            </ContentArea>
          </LazyTabPanel>
          <LazyTabPanel index={2}>
            <ContentArea>
              <NotificationOverviewByResource
                resourceType={ResourceType.project}
                resourceId={id}
              />
              <SectionListLink to={`/projects/${id}/notifications/add`}>
                <IconAddCircleOutline height="24" />
                <span className="ml-1">
                  <FormattedMessage {...translationsNotification.resourceAdd} />
                </span>
              </SectionListLink>
            </ContentArea>
          </LazyTabPanel>
        </TabPanels>
      </Tabs>
      {projectData && (
        <ProjectDetailsFloatingMenu
          project={projectData}
          canAddActivity={hasActivitiesCreatePermissions}
        />
      )}
    </>
  )
}

export { ProjectDetail }
