import React, { createContext, ReactNode, useContext, useState } from 'react'
import { Language } from '../domain/user/types'
import { i18n } from '../config/i18n'

type LanguageContextType = {
  language: Language
  changeLanguage: (newLanguage: Language) => void
}

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
)

export const LanguageProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const currentLanguage = i18n.detectLanguage()
  const [language, setLanguage] = useState(currentLanguage)

  const changeLanguage = (newLanguage: Language) => {
    setLanguage(newLanguage)
    i18n.setLanguage(newLanguage)
  }

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}
