import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { LoginPage } from '../domain/guest/login/LoginPage'
import { ProtectedArea } from '../auth'
import { ProjectsOverviewPage } from '../domain/project/ProjectsOverviewPage'
import { ProjectDetailPage } from '../domain/project/ProjectDetailPage'
import { HomePage } from '../domain/home/HomePage'
import { TimeTrackingOverviewPage } from '../domain/timeTracking/TimeTrackingOverviewPage'
import { TimeTrackingEditPage } from '../domain/timeTracking/TimeTrackingEditPage'
import { NotFoundError } from '../layouts/Error'
import { useHasAuthority } from '../auth/hooks'
import { Authority } from '../auth/types'
import { AddressesOverviewPage } from '../domain/address/AddressesOverviewPage'
import { AddressDetailPage } from '../domain/address/AddressDetailPage'
import { AddressEditPage } from '../domain/address/AddressEditPage'
import { AddressAddPage } from '../domain/address/AddressAddPage'
import { AddressNotificationAddPage } from '../domain/address/AddressNotificationAddPage'
import { AddressNotificationDetailPage } from '../domain/address/AddressNotificationDetailPage'
import { AddressNotificationEditPage } from '../domain/address/AddressNotificationEditPage'
import { ActivityDetailPage } from '../domain/activity/ActivityDetailPage'
import { ActivityEditPage } from '../domain/activity/ActivityEditPage'
import { ActivitySignaturePage } from '../domain/activity/ActivitySignaturePage'
import { ActivityAddPage } from '../domain/activity/ActivityAddPage'
import { ActivityNotificationAddPage } from '../domain/activity/ActivityNotificationAddPage'
import { ActivityNotificationDetailPage } from '../domain/activity/ActivityNotificationDetailPage'
import { ActivityNotificationEditPage } from '../domain/activity/ActivityNotificationEditPage'
import { ProjectEditPage } from '../domain/project/ProjectEditPage'
import { ProjectNotificationAddPage } from '../domain/project/ProjectNotificationAddPage'
import { ProjectNotificationDetailPage } from '../domain/project/ProjectNotificationDetailPage'
import { ProjectNotificationEditPage } from '../domain/project/ProjectNotificationEditPage'
import { DashboardOverviewPage } from '../domain/dashboard/DashboardOverviewPage'
import { ActivitiesOverviewGroupPage } from '../domain/activity/ActivitiesOverviewGroupPage'

type ProtectedRouteProps = {
  authority: Authority
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  authority,
  children,
}) => {
  const hasPermission = useHasAuthority(authority)

  return hasPermission ? <>{children}</> : <NotFoundError />
}

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="*"
        element={
          <ProtectedArea>
            <Routes>
              <Route path="/dashboard" element={<DashboardOverviewPage />} />
              <Route path="/projects" element={<ProjectsOverviewPage />} />
              <Route path="/projects/:id" element={<ProjectDetailPage />} />
              <Route path="/projects/:id/edit" element={<ProjectEditPage />} />
              <Route
                path="/projects/:id/notifications/add"
                element={<ProjectNotificationAddPage />}
              />
              <Route
                path="/projects/:id/notifications/:notificationId"
                element={<ProjectNotificationDetailPage />}
              />
              <Route
                path="/projects/:id/notifications/:notificationId/edit"
                element={<ProjectNotificationEditPage />}
              />
              <Route
                path="/time-tracking"
                element={
                  <ProtectedRoute authority={Authority.TIME_TRACKING_READ}>
                    <TimeTrackingOverviewPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/time-tracking/:id/edit"
                element={<TimeTrackingEditPage />}
              />
              <Route
                path="/addresses"
                element={
                  <ProtectedRoute authority={Authority.ADDRESSES_READ}>
                    <AddressesOverviewPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/addresses/add"
                element={
                  <ProtectedRoute authority={Authority.ADDRESSES_CREATE}>
                    <AddressAddPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/addresses/:id"
                element={
                  <ProtectedRoute authority={Authority.ADDRESSES_READ}>
                    <AddressDetailPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/addresses/:id/edit"
                element={
                  <ProtectedRoute authority={Authority.ADDRESSES_EDIT}>
                    <AddressEditPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/addresses/:id/notifications/add"
                element={<AddressNotificationAddPage />}
              />
              <Route
                path="/addresses/:id/notifications/:notificationId"
                element={<AddressNotificationDetailPage />}
              />
              <Route
                path="/addresses/:id/notifications/:notificationId/edit"
                element={<AddressNotificationEditPage />}
              />
              <Route
                path="/activity-groups/:id"
                element={
                  <ProtectedRoute authority={Authority.ACTIVITIES_READ}>
                    <ActivitiesOverviewGroupPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/activity-groups/:activityGroupId/activities/add"
                element={
                  <ProtectedRoute authority={Authority.ACTIVITIES_CREATE}>
                    <ActivityAddPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/activity-groups/:activityGroupId/activities/:activityId"
                element={
                  <ProtectedRoute authority={Authority.ACTIVITIES_READ}>
                    <ActivityDetailPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/activity-groups/:activityGroupId/activities/:activityId/sign"
                element={
                  <ProtectedRoute authority={Authority.ACTIVITIES_EDIT}>
                    <ActivitySignaturePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/activity-groups/:activityGroupId/activities/:activityId/edit"
                element={
                  <ProtectedRoute authority={Authority.ACTIVITIES_EDIT}>
                    <ActivityEditPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/activity-groups/:activityGroupId/activities/:activityId/notifications/add"
                element={<ActivityNotificationAddPage />}
              />
              <Route
                path="/activity-groups/:activityGroupId/activities/:activityId/notifications/:notificationId"
                element={<ActivityNotificationDetailPage />}
              />
              <Route
                path="/activity-groups/:activityGroupId/activities/:activityId/notifications/:notificationId/edit"
                element={<ActivityNotificationEditPage />}
              />
              <Route path="/" element={<HomePage />} />
              <Route path="*" element={<NotFoundError />} />
            </Routes>
          </ProtectedArea>
        }
      />
    </Routes>
  )
}

export { AppRoutes }
