import { AxiosRequestConfig } from 'axios'
import { Language } from '../domain/user/types'

export enum Authority {
  ACTIVITIES_CREATE = 'ACTIVITIES_CREATE',
  ACTIVITIES_DELETE = 'ACTIVITIES_DELETE',
  ACTIVITIES_EDIT = 'ACTIVITIES_EDIT',
  ACTIVITIES_READ = 'ACTIVITIES_READ',

  ADDRESSES_CREATE = 'ADDRESSES_CREATE',
  ADDRESSES_DELETE = 'ADDRESSES_DELETE',
  ADDRESSES_EDIT = 'ADDRESSES_EDIT',
  ADDRESSES_READ = 'ADDRESSES_READ',

  TIME_TRACKING_READ = 'TIME_TRACKING_READ',
  TIME_TRACKING_PAUSE_CREATE = 'TIME_TRACKING_PAUSE_CREATE',
  TIME_TRACKING_PAUSE_DELETE = 'TIME_TRACKING_PAUSE_DELETE',
  TIME_TRACKING_PAUSE_EDIT = 'TIME_TRACKING_PAUSE_EDIT',

  TIME_TRACKING_PROJECT_AND_COST_CENTER_CREATE = 'TIME_TRACKING_PROJECT_AND_COST_CENTER_CREATE',
  TIME_TRACKING_PROJECT_AND_COST_CENTER_DELETE = 'TIME_TRACKING_PROJECT_AND_COST_CENTER_DELETE',
  TIME_TRACKING_PROJECT_AND_COST_CENTER_EDIT = 'TIME_TRACKING_PROJECT_AND_COST_CENTER_EDIT',

  TIME_TRACKING_START_CREATE = 'TIME_TRACKING_START_CREATE',
  TIME_TRACKING_START_DELETE = 'TIME_TRACKING_START_DELETE',
  TIME_TRACKING_START_EDIT = 'TIME_TRACKING_START_EDIT',

  TIME_TRACKING_STOP_CREATE = 'TIME_TRACKING_STOP_CREATE',
  TIME_TRACKING_STOP_DELETE = 'TIME_TRACKING_STOP_DELETE',
  TIME_TRACKING_STOP_EDIT = 'TIME_TRACKING_STOP_EDIT',
}

export type User = {
  id: string
  name: string
  email: string
  language: Language
  username: string
}

export const TIME_TRACKING_CREATE_AUTHORITIES = [
  Authority.TIME_TRACKING_PAUSE_CREATE,
  Authority.TIME_TRACKING_STOP_CREATE,
  Authority.TIME_TRACKING_START_CREATE,
  Authority.TIME_TRACKING_PROJECT_AND_COST_CENTER_CREATE,
]

export type Me = {
  user: User
  authorities: Authority[]
}

export type RetryQueueItem = {
  resolve: (value?: any) => void
  reject: (error?: any) => void
  config: AxiosRequestConfig
}
