import * as Sentry from '@sentry/browser'
import { getNotifications } from './api'
import { overrideNotifications } from './db/notifications'

const syncNotifications = async (): Promise<void> => {
  try {
    const notifications = await getNotifications()
    await overrideNotifications(notifications)
  } catch (error: any) {
    if (error.response.status === 401) {
    } else {
      Sentry.captureException(error)
    }
  }
}

export { syncNotifications }
