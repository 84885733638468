import React from 'react'
import classNames from 'classnames'
import { Progress } from '../types'
import { ProgressIcon } from './ProgressIcon'

export function ProgressWithIcon({
  progress,
  className,
}: {
  progress: Progress | undefined
  className?: string
}) {
  return progress ? (
    <div className={classNames('flex items-center gap-1', className)}>
      <ProgressIcon progress={progress} className={'size-4'} />
      <span>{progress.name}</span>
    </div>
  ) : null
}
