import { useMemo } from 'react'
import { Activity, ActivityFieldState, Group } from '../types'
import { Project } from '../../project/types'
import { Notification, NotificationType } from '../../notification/types'
import { useGetProjectsByIds } from '../../project/db/projects'
import { useGetNotificationsByResources } from '../../notification/db/notifications'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { PaginatedData } from '../../../db/types'
import { useGetAllUsers } from '../../user/db/users'
import { useGetAddressGroupsForNotifications } from '../../address/db/addressGroups'
import { populateNotificationData } from '../../notification/overview/populateNotificationData'
import { useGetFilteredCategoriesForActivityGroup } from '../utils'
import { useGetAddressesByIds } from '../../address/db/addresses'
import { Address } from '../../address/types'

export type PopulatedActivity = Activity & {
  progress?: {
    name: string
    icon?: string
  }
  projectName?: string
  assignees?: string[]
  addressName?: string
}

const getGetAssignedProject = (
  activity: Activity,
  projects: Project[] | null
) => projects?.find((project) => project.id === activity.projectId) ?? null

const getAssignedAddress = (activity: Activity, addresses: Address[] | null) =>
  addresses?.find((address) => address.id === activity.addressId) ?? null

const getAssignedEmployeeNotifications = (
  activity: Activity,
  notifications: Notification[] | null
) =>
  notifications?.filter(
    (notification) =>
      notification.type === NotificationType.is_responsible &&
      notification.referencedResourceId === activity.id
  ) ?? []

function usePopulateActivity(
  activities: PaginatedData<Activity>,
  activityGroup: Group
): PopulatedActivity[] {
  const ids = useMemo(
    () => activities.items.map((activity) => activity.id),
    [activities.items]
  )

  const categories = useGetFilteredCategoriesForActivityGroup(activityGroup)
  const projectIds = useMemo(
    () => activities.items.map((activity) => activity.projectId),
    [activities.items]
  )
  const addressesIds = useMemo(
    () => activities.items.map((activity) => activity.addressId),
    [activities.items]
  )
  const { data: projects } = useGetProjectsByIds(projectIds)
  const { data: notifications } = useGetNotificationsByResources(
    ResourceType.activity,
    ids
  )
  const { data: addresses } = useGetAddressesByIds(addressesIds)
  const { data: users } = useGetAllUsers()
  const { data: addressGroups } = useGetAddressGroupsForNotifications()

  return activities.items.map((item) => {
    const category = categories?.find(
      (category) => category.id === item.activityCategoryId
    )
    const progress = category?.progresses.find(
      (progress) => progress.id === item.activityProgressId
    )
    const project = getGetAssignedProject(item, projects)
    const address = getAssignedAddress(item, addresses)
    const employeesNotifications = getAssignedEmployeeNotifications(
      item,
      notifications
    )
    const assignees = populateNotificationData(employeesNotifications, {
      users,
      addressGroups,
    })
      ?.filter((notification) => notification.assignedName != null)
      .map((notification) => notification.assignedName ?? '')

    return {
      ...item,
      progress:
        activityGroup.fields.activityProgressId.state !==
          ActivityFieldState.Hidden && progress
          ? {
              name: progress.name,
              icon: progress.icon,
            }
          : undefined,
      projectName: project?.name,
      assignees: assignees,
      addressName: address?.name,
    }
  })
}

export { usePopulateActivity }
