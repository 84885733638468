import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axios, { AxiosResponse, CancelToken, CancelTokenSource } from 'axios'
import { CircleBouncer } from '../../../shared/loading/CircleBouncer'
import { IconDelete } from '../../../shared/svgs'
import { SectionItem } from '../../../shared/section'
import { FileToUpload } from '../types'
import { translationsShared } from '../../../shared/translations/translationsShared'

const FileToUploadFormItem: React.FC<{
  fileToUpload: FileToUpload
  onUpload: (
    file: FileToUpload,
    cancelToken: CancelToken
  ) => Promise<AxiosResponse>
  onSuccess: () => void
  onDelete: () => void
}> = ({ fileToUpload, onUpload, onSuccess, onDelete }) => {
  const [isUploadStarted, setIsUploadStarted] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [axiosSource] = useState<CancelTokenSource>(() =>
    axios.CancelToken.source()
  )
  const intl = useIntl()

  useEffect(() => {
    const uploadFile = async (file: FileToUpload) => {
      try {
        await onUpload(
          {
            fileId: fileToUpload.fileId,
            file: file.file,
          },
          axiosSource.token
        )
        onSuccess()
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          setErrorMessage(
            error.response.data.errors.file
              ? error.response.data.errors.file[0]
              : error.response.data.message
          )
        } else {
          setErrorMessage(
            intl.formatMessage(translationsShared.fileUnknownError)
          )
        }
      }
    }

    if (!isUploadStarted) {
      uploadFile(fileToUpload)
      setIsUploadStarted(true)
    }
  }, [fileToUpload, intl, onSuccess, onUpload, isUploadStarted, axiosSource])

  useEffect(() => () => axiosSource.cancel(), [axiosSource])

  return (
    <SectionItem>
      <div className="flex flex-1 flex-col">
        <div className="mb-8 flex flex-row items-start justify-between">
          {isUploadStarted && !errorMessage && <CircleBouncer />}
          {errorMessage && <div className="text-red-100">{errorMessage}</div>}
          <button
            type="button"
            aria-label={intl.formatMessage(translationsShared.fileFormDelete)}
            onClick={onDelete}
          >
            <IconDelete className="h-5" />
          </button>
        </div>
        <div>{fileToUpload.file.name}</div>
      </div>
    </SectionItem>
  )
}

export { FileToUploadFormItem }
