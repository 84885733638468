import axios, { AxiosResponse } from 'axios'
import { TimeTrackingRecord } from './types'

export type TimeTrackingSyncPayload = {
  timestamp: string | null
  upsertedRecords: Array<TimeTrackingRecord>
  deletedRecords: Array<{ id: string }>
}

export type TimeTrackingSyncResponse = {
  timestamp: string
  upsertedRecords: Array<TimeTrackingRecord>
  deletedRecords: Array<{ id: string }>
}

const syncTimeTrackingRecords = (
  payload: TimeTrackingSyncPayload
): Promise<AxiosResponse<TimeTrackingSyncResponse>> =>
  axios.put('/v1/user/time-tracking/sync', payload)

export { syncTimeTrackingRecords }
