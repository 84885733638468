import { FC, SVGProps } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { ActivityPriority } from '../types'
import { translationsPriority } from '../translations/translationsActivity'
import IconPriorityLow from './IconPriorityLow'
import IconPriorityNormal from './IconPriorityNormal'
import IconPriorityVeryHigh from './IconPriorityVeryHigh'
import IconPriorityVeryLow from './IconPriorityVeryLow'
import { IconPriorityHigh } from './IconPriorityHigh'

export const PriorityIcons: Record<
  ActivityPriority,
  FC<SVGProps<SVGSVGElement>>
> = {
  [ActivityPriority.veryLow]: IconPriorityVeryLow,
  [ActivityPriority.low]: IconPriorityLow,
  [ActivityPriority.normal]: IconPriorityNormal,
  [ActivityPriority.high]: IconPriorityHigh,
  [ActivityPriority.veryHigh]: IconPriorityVeryHigh,
}

export function PriorityIconWithDescription({
  priority,
  className,
}: {
  priority: ActivityPriority
  className?: string
}) {
  const Icon = PriorityIcons[priority]
  return (
    <div className={'flex items-center gap-1'}>
      <Icon className={classNames('size-4', className)} />
      <FormattedMessage {...translationsPriority(priority)} />
    </div>
  )
}
