import { useContext } from 'react'
import { Authority } from './types'
import { AuthContext } from './AuthContext'

function useHasAuthority(searchedScope: Authority): boolean {
  const authContext = useContext(AuthContext)
  const authorities =
    authContext.data === null ? [] : authContext.data.authorities

  return authorities.includes(searchedScope)
}

export function useHaveOneOfAuthorities(
  searchedAuthorities: Authority[]
): boolean {
  const authContext = useContext(AuthContext)
  const authorities =
    authContext.data === null ? [] : authContext.data.authorities

  const permissions = authorities.filter((currentPermission) => {
    return searchedAuthorities.includes(currentPermission)
  })

  return permissions.length > 0
}

export const useCreatePermissionsTimeTracking = () => {
  return {
    hasCreatePermissionStart: useHasAuthority(
      Authority.TIME_TRACKING_START_CREATE
    ),
    hasCreatePermissionStop: useHasAuthority(
      Authority.TIME_TRACKING_STOP_CREATE
    ),
    hasCreatePermissionPause: useHasAuthority(
      Authority.TIME_TRACKING_PAUSE_CREATE
    ),
    hasCreatePermissionProjectCostCenter: useHasAuthority(
      Authority.TIME_TRACKING_PROJECT_AND_COST_CENTER_CREATE
    ),
  }
}

export { useHasAuthority }
