import { Progress } from '../types'

export function ProgressIcon({
  progress,
  className,
}: {
  progress: Pick<Progress, 'icon' | 'name'>
  className?: string
}) {
  return progress.icon ? (
    <img
      src={`data:image/svg+xml;base64,${progress.icon}`}
      alt={progress.name}
      className={className}
    />
  ) : null
}
