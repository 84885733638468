import Dexie from 'dexie'
import { getMe } from '../auth/api'
import { syncTimeTracking } from '../domain/timeTracking/sync'
import { Authority, Me } from '../auth/types'
import { syncAddressConfigs, syncAddresses } from '../domain/address/sync'
import { syncCostCenter } from '../domain/costCenter/sync'
import { syncProjects } from '../domain/project/sync'
import {
  syncActivities,
  syncActivityConfigs,
  syncActivityHierarchies,
  syncArticles,
} from '../domain/activity/sync'
import { syncNotifications } from '../domain/notification/sync'
import { syncSettings } from '../domain/settings/sync'
import { syncUsers } from '../domain/user/sync'
import { syncFiles } from '../domain/file/sync'
import { setAuthMe, setSyncDate } from './tables/settings'
import {
  DELETED_TABLE_ACTIVITY_TYPES,
  TABLE_ACTIVITIES,
  TABLE_ACTIVITIES_CONFIG,
  TABLE_ACTIVITY_CATEGORIES,
  TABLE_ACTIVITY_GROUP_SETTINGS,
  TABLE_ACTIVITY_GROUPS,
  TABLE_ACTIVITY_HIERARCHIES,
  TABLE_ADDRESS_GROUPS,
  TABLE_ADDRESS_TYPES,
  TABLE_ADDRESSES,
  TABLE_ARTICLES,
  TABLE_COST_CENTER_GROUPS,
  TABLE_COST_CENTERS,
  TABLE_FILES,
  TABLE_NOTIFICATIONS,
  TABLE_PROJECTS,
  TABLE_SETTINGS,
  TABLE_TIME_TRACKING_BUFFER,
  TABLE_TIME_TRACKING_CONFIG,
  TABLE_TIME_TRACKING_RECORDS,
  TABLE_USER_ADDRESS_GROUPS,
  TABLE_USERS,
} from './types'

const db = new Dexie('zagzag')

db.version(1).stores({
  [TABLE_SETTINGS]: 'id',
  [TABLE_PROJECTS]: 'id,name,sort,*search',
  [TABLE_COST_CENTERS]: 'id,sort,*search',
  [TABLE_TIME_TRACKING_CONFIG]: 'id',
  [TABLE_TIME_TRACKING_RECORDS]: 'id,time',
  [TABLE_TIME_TRACKING_BUFFER]: '++id,timeTrackingId',
})

db.version(2).stores({
  [TABLE_ADDRESSES]: 'id,name,sort,*search',
})

db.version(3).stores({
  [TABLE_ACTIVITIES]: 'id,name,sort,*search',
  [TABLE_ACTIVITIES_CONFIG]: 'id',
})

db.version(4).stores({
  [TABLE_ARTICLES]: 'id,name,*search',
})

db.version(5).stores({
  [TABLE_NOTIFICATIONS]: 'id',
  [TABLE_ADDRESS_GROUPS]: 'id',
  [TABLE_USERS]: 'id',
})

db.version(6).stores({
  [TABLE_ADDRESSES]: 'id,name,*search,parentId',
})

db.version(7)
  .stores({
    [TABLE_PROJECTS]: 'id,name,sort,*searchIndexed',
    [TABLE_COST_CENTERS]: 'id,sort,*searchIndexed',
    [TABLE_ADDRESSES]: 'id,name,*searchIndexed,parentId',
    [TABLE_ACTIVITIES]: 'id,name,sortIndexed,*searchIndexed',
    [TABLE_ARTICLES]: 'id,name,*searchIndexed',
  })
  .upgrade((trans) => {
    return Promise.all([
      trans
        .table(TABLE_PROJECTS)
        .toCollection()
        .modify((project) => {
          project.searchIndexed = project.search
          delete project.search
        }),

      trans
        .table(TABLE_COST_CENTERS)
        .toCollection()
        .modify((costCenter) => {
          costCenter.searchIndexed = costCenter.search
          delete costCenter.search
        }),

      trans
        .table(TABLE_ADDRESSES)
        .toCollection()
        .modify((address) => {
          address.searchIndexed = address.search
          address.search = address.name
        }),

      trans
        .table(TABLE_ACTIVITIES)
        .toCollection()
        .modify((activity) => {
          activity.searchIndexed = activity.search
          activity.sortIndexed = activity.sort
          activity.search = activity.name
          delete activity.sort
        }),

      trans
        .table(TABLE_ARTICLES)
        .toCollection()
        .modify((article) => {
          article.searchIndexed = article.search
          delete article.search
        }),
    ])
  })

db.version(8)
  .stores({
    [TABLE_PROJECTS]:
      'id,name,sort,*searchIndexed,options.visibility.projects,options.visibility.timeTracking',
  })
  .upgrade((trans) => {
    return trans
      .table(TABLE_PROJECTS)
      .toCollection()
      .modify((project) => {
        if (project.options === undefined) {
          project.options = {}
        }

        if (project.options.visibility === undefined) {
          project.options.visibility = {
            projects: true,
            timeTracking: true,
          }
        }

        if (project.options.visibility.projects === undefined) {
          project.options.visibility.projects = true
        }

        if (project.options.visibility.timeTracking === undefined) {
          project.options.visibility.timeTracking = true
        }
      })
  })

db.version(9).stores({
  [TABLE_COST_CENTER_GROUPS]: 'id,sort,*searchIndexed',
})

db.version(10).stores({
  [TABLE_ARTICLES]: 'id,name,*searchIndexed,*codes',
})

db.version(11)
  .stores({
    [TABLE_ACTIVITIES]: 'id,name,sortIndexed,*searchIndexed',
    [TABLE_USER_ADDRESS_GROUPS]: 'id',
  })
  .upgrade((trans) => {
    return trans
      .table(TABLE_ACTIVITIES)
      .toCollection()
      .modify((activity) => {
        if (activity.addressId !== undefined && activity.addressId !== null) {
          activity.addressIds = [activity.addressId]
        }

        delete activity.addressId
        delete activity.users
      })
  })

db.version(12).stores({
  [TABLE_ACTIVITIES_CONFIG]: null,
  [DELETED_TABLE_ACTIVITY_TYPES]: 'id',
  [TABLE_ACTIVITY_GROUPS]: 'id',
})

db.version(13).stores({
  [TABLE_ACTIVITY_CATEGORIES]: 'id',
})

db.version(14)
  .stores({
    [TABLE_ADDRESSES]: 'id,name,*sortIndexed,*searchIndexed,parentId',
  })
  .upgrade((trans) => {
    return trans
      .table(TABLE_ADDRESSES)
      .toCollection()
      .modify((address) => {
        address.sortIndexed = address.sort
      })
  })

db.version(15).stores({
  [DELETED_TABLE_ACTIVITY_TYPES]: null,
})

db.version(16).stores({
  [TABLE_ADDRESS_TYPES]: 'id',
})

db.version(17).stores({
  [TABLE_ARTICLES]: 'id,name,*sortIndexed,*searchIndexed,*codes',
  [TABLE_NOTIFICATIONS]: 'id,*sortIndexed',
  [TABLE_ACTIVITY_GROUPS]: 'id,*sortIndexed',
  [TABLE_ADDRESS_TYPES]: 'id,*sortIndexed',
})

db.version(18).stores({
  [TABLE_ACTIVITIES]: 'id,name,sortIndexed,startDateIndexed,*searchIndexed',
})

db.version(19).stores({
  [TABLE_ACTIVITY_HIERARCHIES]: 'parentId,childId',
})

db.version(20).stores({
  [TABLE_ACTIVITY_HIERARCHIES]: null,
})

db.version(21).stores({
  [TABLE_ACTIVITY_HIERARCHIES]: '[parentId+childId],parentId,childId',
})

db.version(22)
  .stores({
    [TABLE_USERS]: 'id,name,*sortIndexed,*searchIndexed',
  })
  .upgrade((trans) => {
    return trans
      .table(TABLE_USERS)
      .toCollection()
      .modify((user) => {
        user.searchIndexed = user.name
        user.sortIndexed = user.name
      })
  })

db.version(23).stores({
  [TABLE_ACTIVITY_GROUP_SETTINGS]: 'id, assignedUserId',
})

db.version(24).stores({
  [TABLE_FILES]: 'id',
})

const clearDB = (): Promise<void[]> => {
  return Promise.all(
    db.tables.map((table) => {
      return db.table(table.name).clear()
    })
  )
}

const syncDB = async (
  authorities: Array<Authority> | undefined
): Promise<void> => {
  if (authorities === undefined) return

  await Promise.all([
    syncProjects(),
    syncAddresses(authorities),
    syncAddressConfigs(authorities),
    syncCostCenter(authorities),
    syncTimeTracking(authorities),
    syncActivities(authorities),
    syncActivityConfigs(authorities),
    syncActivityHierarchies(authorities),
    syncArticles(authorities),
    syncNotifications(),
    syncUsers(),
    syncSettings(),
    syncFiles(),
  ])

  const date = new Date()
  await setSyncDate(date)
}

const syncAuth = async (): Promise<Me> => {
  const me = await getMe()

  await setAuthMe(me)

  return me
}

export { db, syncDB, syncAuth, clearDB }
