import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'

import { RecordType, TimeTrackingRecord } from '../types'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { InputWithLabel } from '../../../shared/form'

import { ContentArea } from '../../../shared/content'
import { syncTimeTracking } from '../sync'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { SelectProject } from '../shared/SelectProject'
import { SelectCostCenter } from '../shared/SelectCostCenter'
import { updateTimeTrackingRecord } from '../db/records'
import { addUpsertBufferEntry } from '../db/buffer'
import { SectionActions } from '../../../shared/section/SectionActions'
import { AuthContext } from '../../../auth/AuthContext'
import { Authority } from '../../../auth/types'
import { LeaveDirtyFormPrompt } from '../../../shared/form/LeaveDirtyFormPrompt'
import { ProjectVisibility } from '../../project/types'
import {
  extractDateFromTimestamp,
  extractTimeFromTimestamp,
} from '../../../shared/utils/dateUtils'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsTimetracking } from '../translations/translationsTimeTracking'
import { useHasAuthority } from '../../../auth/hooks'
import { NotFoundError } from '../../../layouts/Error'

type TimeTrackingFormValues = {
  time: string
  date: string
  description: string | null
  projectId: string | null
  costCenterId: string | null
}

async function updateRecord(
  old: TimeTrackingRecord,
  { date, time, ...values }: TimeTrackingFormValues,
  permissions?: Array<Authority>
) {
  const milliseconds = old.time.substr(16)
  const updatedDateTime = date + 'T' + time + milliseconds

  const timeTrackingEntry = {
    ...old,
    time: updatedDateTime,
    ...values,
  }

  await updateTimeTrackingRecord(timeTrackingEntry)
  await addUpsertBufferEntry(timeTrackingEntry)
  if (permissions !== undefined) {
    await syncTimeTracking(permissions)
  }
}

function getAuthorityFromRecordType(type: RecordType): Authority {
  switch (type) {
    case RecordType.WORK_START:
      return Authority.TIME_TRACKING_START_EDIT
    case RecordType.WORK_STOP:
      return Authority.TIME_TRACKING_STOP_EDIT
    case RecordType.WORK_PAUSE:
      return Authority.TIME_TRACKING_PAUSE_EDIT
    case RecordType.PROJECT_COSTCENTER:
      return Authority.TIME_TRACKING_PROJECT_AND_COST_CENTER_EDIT
  }
}

const TimeTrackingEditForm: React.FC<{ data: TimeTrackingRecord }> = ({
  data,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { data: userPermissions } = useContext(AuthContext)

  const hasPermission = useHasAuthority(getAuthorityFromRecordType(data.type))

  if (!hasPermission) {
    return <NotFoundError />
  }

  const cancel = () => {
    navigate(-1)
  }

  const initialValues: TimeTrackingFormValues = {
    time: extractTimeFromTimestamp(data.time),
    date: extractDateFromTimestamp(data.time),
    description: data.description,
    projectId: data.projectId,
    costCenterId: data.costCenterId,
  }

  const showProjectAndCostCenterInputs =
    data.type === RecordType.PROJECT_COSTCENTER

  const showDescriptionInput = data.type === RecordType.PROJECT_COSTCENTER

  const schema = Yup.object().shape({
    time: Yup.string()
      .matches(/^\d\d:\d\d$/, {
        message: intl.formatMessage(translationsShared.validationTime),
      })
      .required(intl.formatMessage(translationsShared.validationRequired)),
    date: Yup.string()
      .matches(/^\d\d\d\d-\d\d-\d\d$/, {
        message: intl.formatMessage(translationsShared.validationDate),
      })
      .required(intl.formatMessage(translationsShared.validationRequired)),
    ...(showProjectAndCostCenterInputs
      ? {
          projectId: Yup.string().required(
            intl.formatMessage(translationsShared.validationRequired)
          ),
          costCenterId: Yup.string().required(
            intl.formatMessage(translationsShared.validationRequired)
          ),
        }
      : {}),
  })

  const onSubmit = async (
    values: TimeTrackingFormValues,
    { resetForm }: FormikHelpers<TimeTrackingFormValues>
  ) => {
    await updateRecord(data, values, userPermissions?.authorities)

    resetForm({ values })

    navigate(-1)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={false}
    >
      <Form>
        <LeaveDirtyFormPrompt />
        <ContentArea>
          <Section>
            <SectionTitle>
              <FormattedMessage
                id="timeTracking.edit.time.title"
                defaultMessage="Time & Date"
              />
            </SectionTitle>

            <SectionList>
              <SectionItemForm>
                <InputWithLabel
                  type="time"
                  name="time"
                  placeholder={intl.formatMessage(
                    translationsTimetracking.editTimelable
                  )}
                  label={
                    <FormattedMessage
                      {...translationsTimetracking.editTimelable}
                    />
                  }
                />
                <InputWithLabel
                  type="date"
                  name="date"
                  placeholder={intl.formatMessage(
                    translationsTimetracking.editDateLabel
                  )}
                  label={
                    <FormattedMessage
                      {...translationsTimetracking.editDateLabel}
                    />
                  }
                />
              </SectionItemForm>
            </SectionList>
          </Section>
          {showProjectAndCostCenterInputs && (
            <Section>
              <SectionTitle>
                <FormattedMessage
                  id="timeTracking.edit.costCenter.title"
                  defaultMessage="Project & Cost Center"
                />
              </SectionTitle>

              <SectionList>
                <SectionItemForm>
                  <SelectProject
                    projectIdFieldName="projectId"
                    projectVisibility={ProjectVisibility.timeTracking}
                  />
                  <SelectCostCenter costCenterIdFieldName="costCenterId" />
                </SectionItemForm>
              </SectionList>
            </Section>
          )}
          {showDescriptionInput && (
            <Section>
              <SectionTitle>
                <FormattedMessage
                  id="timeTracking.edit.description.title"
                  defaultMessage="Description"
                />
              </SectionTitle>
              <SectionList>
                <SectionItemForm>
                  <InputWithLabel
                    component="textarea"
                    name="description"
                    rows={5}
                    placeholder={intl.formatMessage(
                      translationsTimetracking.editDescriptionLabel
                    )}
                    label={
                      <FormattedMessage
                        {...translationsTimetracking.editDescriptionLabel}
                      />
                    }
                  />
                </SectionItemForm>
              </SectionList>
            </Section>
          )}
          <SectionActions>
            <CancelButton onClick={cancel} />
            <SubmitButton />
          </SectionActions>
        </ContentArea>
      </Form>
    </Formik>
  )
}

export { TimeTrackingEditForm }
