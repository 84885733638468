import { FormattedMessage } from 'react-intl'
import React from 'react'
import classNames from 'classnames'
import { IconCopyright } from '../../shared/svgs'
import { translationsShared } from '../../shared/translations/translationsShared'
import { LanguagePicker } from './LanguagePicker'

type FooterProps = {
  className?: string
  setLocale?: React.Dispatch<React.SetStateAction<string>>
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  const year = new Date().getFullYear()

  return (
    <footer
      className={classNames(
        'text-xs w-full max-w-full flex flex-wrap gap-3 items-center',
        className
      )}
    >
      <a
        href="https://www.camid.eu"
        target="_blank"
        rel="noopener noreferrer"
        className="whitespace-nowrap hover:underline focus:underline"
      >
        <IconCopyright className="inline-block size-4 align-text-top" /> {year}{' '}
        <FormattedMessage {...translationsShared.footerCompany} />
      </a>
      <LanguagePicker />
      <a
        href="https://www.camid.eu/impressum"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:underline focus:underline"
      >
        <FormattedMessage {...translationsShared.footerImprint} />
      </a>
      <a
        href="https://www.camid.eu/privacy"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:underline focus:underline"
      >
        <FormattedMessage {...translationsShared.footerPrivacy} />
      </a>
    </footer>
  )
}

export { Footer }
